<template>
  <div>
    <div>
      <div class="row no-gutters profile-detail-edit">
        <div class="col-md-auto col-12">
          <section class="profile-detail">
            <div class="p-xl-y-30 p-xl-x-45 p-y-30 p-x-15">
              <div class="m-b-25">
                <label class="profile-image-edit">
                  <photo-upload
                    ref="expertPhoto"
                    :preview="expertInfo.photo"
                    v-model="selectedPhoto"
                  >
                  </photo-upload>
                </label>
              </div>
              <div class="m-b-25">
                <!---->
                <h2
                  class="
                    profile-data profile-name profile-code
                    text-primary
                    f-23
                    wt-400
                    mt-0
                    m-b-15
                  "
                >
                  <span class="profile-firstname">
                    <input
                      type="text"
                      :class="
                        !$v.expertInfo.first_name.required &&
                        $v.expertInfo.first_name.$error
                          ? 'is-invalid'
                          : ''
                      "
                      class="input-inline"
                      v-model="expertInfo.first_name"
                    />
                    <div
                      class="form-text f-12 invalid-feedback"
                      v-if="
                        !$v.expertInfo.first_name.required &&
                        $v.expertInfo.first_name.$error
                      "
                    >
                      This first name is required.
                    </div>
                  </span>
                  <span class="profile-lastname">
                    <input
                      type="text"
                      :class="
                        !$v.expertInfo.last_name.required &&
                        $v.expertInfo.last_name.$error
                          ? 'is-invalid'
                          : ''
                      "
                      class="input-inline"
                      v-model="expertInfo.last_name"
                    />
                    <div
                      class="form-text f-12 invalid-feedback"
                      v-if="
                        !$v.expertInfo.last_name.required &&
                        $v.expertInfo.last_name.$error
                      "
                    >
                      This last name is required.
                    </div>
                  </span>
                </h2>
                <div class="profile-data f-14 m-b-10">
                  <div class="dropdown">
                    <a
                      href=""
                      class="dropdown-input-inline"
                      data-toggle="dropdown"
                    >
                      <i
                        class="mdi mdi-circle mr-2 online-status-text"
                        :class="kebabCase(expertInfo.available_per)"
                      ></i>
                      {{ expertInfo.available_per }}</a
                    >
                    <div class="dropdown-menu">
                      <template v-for="(name, key) in availablePer">
                        <a
                          class="dropdown-item f-14"
                          href="javascript:void(0)"
                          @click="setAvailablePer(name, key)"
                        >
                          <i
                            class="mdi mdi-circle mr-2 online-status-text"
                            :class="kebabCase(name)"
                          ></i>
                          {{ name }}</a
                        >
                      </template>
                      <div
                        class="form-text f-12 is-invalid-feedback"
                        v-if="
                          !$v.expertInfo.available_per_id.required &&
                          $v.expertInfo.available_per_id.$error
                        "
                      >
                        This field is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-data f-14 m-b-10">
                  <i class="mdi mdi-airplane text-primary mr-2"></i>Working
                  area: &nbsp;
                  <strong class="wt-500">
                    <select
                      :class="
                        !$v.expertInfo.willingness_to_travel.required &&
                        $v.expertInfo.willingness_to_travel.$error
                          ? 'is-invalid'
                          : ''
                      "
                      class="input-inline"
                      v-model="expertInfo.willingness_to_travel"
                    >
                      <option
                        v-for="option in willingnessOption"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <div
                      class="form-text f-12 invalid-feedback"
                      v-if="
                        !$v.expertInfo.willingness_to_travel.required &&
                        $v.expertInfo.willingness_to_travel.$error
                      "
                    >
                      This field is required.
                    </div>
                  </strong>
                </div>
                <div class="profile-data f-14">
                  <i class="mdi mdi-briefcase text-primary mr-2"></i>Rate &nbsp;
                  <strong class="wt-500">
                    <div>
                      <select
                        class="input-inline w-100"
                        v-model="expertInfo.target_day_rate"
                      >
                        <option v-for="dayRate in dayRates" :value="dayRate">
                          {{ dayRate }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-text f-12 is-invalid-feedback"
                      v-if="
                        !$v.expertInfo.target_day_rate.required &&
                        $v.expertInfo.target_day_rate.$error
                      "
                    >
                      This field is required.
                    </div>

                    <div
                      class="form-text f-12 is-invalid-feedback"
                      v-if="
                        !$v.expertInfo.target_day_rate.decimal &&
                        $v.expertInfo.target_day_rate.$error
                      "
                    >
                      This field is invalid.
                    </div>
                  </strong>
                </div>
              </div>
              <hr class="m-y-25" />
              <div class="m-b-25">
                <div class="profile-data">
                  <div class="f-14 text-primary">
                    <i class="mdi mdi-earth mr-2"></i>Country
                  </div>
                  <div>
                    <multiselect
                      v-model="expertInfo.country_id"
                      :options="countries"
                      :searchable="true"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder
                      selectedLabel
                      selectLabel
                      deselectLabel
                      track-by="three_letter_country_code"
                      label="country_name"
                      openDirection="below"
                    ></multiselect>
                  </div>
                </div>
              </div>
              <div class="m-b-25">
                <div class="profile-data">
                  <div class="f-14 text-primary">
                    <i class="mdi mdi-map-marker mr-2"></i>Location
                  </div>
                  <div>
                    <input
                      type="text"
                      :class="
                        !$v.expertInfo.location.required &&
                        $v.expertInfo.location.$error
                          ? 'is-invalid'
                          : ''
                      "
                      class="input-inline w-100"
                      v-model="expertInfo.location"
                    />
                    <div
                      class="form-text f-12 invalid-feedback"
                      v-if="
                        !$v.expertInfo.location.required &&
                        $v.expertInfo.location.$error
                      "
                    >
                      This field is required.
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-y-25" />
              <div class="m-b-25">
                <div class="profile-data">
                  <div class="f-14 text-primary">
                    <i class="mdi mdi-at mr-2"></i>Email
                  </div>
                  {{ expertInfo.email }}
                </div>
              </div>
              <div class="m-b-25">
                <div class="profile-data">
                  <div class="f-14 text-primary">
                    <i class="mdi mdi-phone mr-2"></i>Phone
                  </div>
                  <input
                    type="text"
                    :class="
                      !$v.expertInfo.phone.required &&
                      $v.expertInfo.phone.$error
                        ? 'is-invalid'
                        : ''
                    "
                    class="input-inline w-100"
                    v-model="expertInfo.phone"
                  />
                  <div
                    class="form-text f-12 invalid-feedback"
                    v-if="
                      !$v.expertInfo.phone.required &&
                      $v.expertInfo.phone.$error
                    "
                  >
                    This field is required.
                  </div>
                </div>
              </div>
              <hr class="m-y-25" />
              <div class="m-b-25">
                <div class="profile-data">
                  <div class="f-14 text-primary m-b-15">Resume</div>
                  <a
                    :href="expertInfo.resume"
                    target="_blank"
                    v-if="expertInfo.resume !== null"
                    class="btn btn-light btn-block btn-sm m-b-10"
                  >
                    <i class="mdi mdi-eye mr-1"></i>View current resume
                  </a>
                  <a
                    v-if="expertInfo.resume === null"
                    href="javascript:void(0);"
                    class="
                      btn btn-outline-info btn-block btn-sm
                      inactiveLink
                      m-b-10
                    "
                    ><i class="mdi mdi-file-pdf mr-1"> </i>
                    Resume not available
                  </a>
                  <div class="custom-file custom-file-resume">
                    <input
                      type="file"
                      class="custom-file-input"
                      :class="
                        isValidResume == 0 ||
                        (!$v.selectedResume.required &&
                          $v.selectedResume.$error)
                          ? 'is-invalid'
                          : ''
                      "
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01"
                      @change="onResumeSelected"
                      :disabled="isSavingExpert"
                    />
                    <label
                      class="custom-file-label custom-file-btn"
                      for="inputGroupFile01"
                      data-display="Update resume"
                    >
                    </label>
                    <div
                      class="form-text f-12 invalid-feedback"
                      v-show="
                        !$v.selectedResume.required && $v.selectedResume.$error
                      "
                    >
                      This field is required.
                    </div>
                    <div
                      class="form-text f-12 m-t-15 invalid-feedback"
                      v-show="hasExtensionError == 1"
                    >
                      Invalid reume extension. Only {{ validExtensions }} are
                      accepted.
                    </div>
                    <div
                      class="form-text f-12 m-t-15 invalid-feedback"
                      v-show="hasSizeError == 1 && hasExtensionError == 0"
                    >
                      The reume size must be less than or equal to
                      {{ (size / 1e6).toFixed(1) }} MB.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-md col-12 col-profile-detail-full">
          <div class="p-xl-45">
            <div
              class="card shadow-sm p-md-30 p-x-15 p-y-30 card-expert-detail"
            >
              <div class="row">
                <div class="col">
                  Please add, delete or adjust the fields below, so that they
                  reflect your key experiences and capabilities only(maximum 3-4
                  per category)
                </div>
                <div class="text-right col-auto">
                  <button
                    type="button"
                    :disabled="isSavingExpert"
                    class="btn btn-primary p-x-25"
                    @click="saveExpert"
                  >
                    <span
                      v-show="isSavingExpert"
                      class="mdi mdi-spin mdi-loading"
                    ></span>
                    <span>Save</span>
                  </button>
                  <button
                    type="button"
                    @click="cancel"
                    :disabled="isSavingExpert"
                    class="btn btn-light p-x-15"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <hr class="m-y-30" />
              <!---->
              <h3 class="text-primary f-20 wt-300">
                <i class="mdi mdi-domain mr-3"></i>Industry, Sector, Company
              </h3>
              <industry
                ref="expertIndustries"
                :expertIndustries="expertIndustries"
              />
              <hr class="m-y-30" />
              <h3 class="text-primary f-20 wt-300">
                <i class="mdi mdi-star mr-3"></i>Department, Function, Topic
              </h3>
              <experience
                ref="expertExperiences"
                :expertExperiences="expertExperiences"
              />
              <hr class="m-y-30" />
              <h3 class="text-primary f-20 wt-300">
                <i class="mdi mdi-translate mr-3"></i>Language
              </h3>
              <div class="table-overflow">
                <table class="table-detail f-14">
                  <tbody>
                    <tr>
                      <th>
                        <div class="table-cell">
                          LANGUAGE MT
                          <div class="f-10">Mother tongue</div>
                        </div>
                      </th>
                      <td>
                        <div class="table-cell">
                          <multiselect
                            v-model="expertInfo.language_mt"
                            :options="languages"
                            ref="multiselect_languaget_mt"
                            :searchable="true"
                            :preselect-first="true"
                            :close-on-select="true"
                            :show-labels="false"
                            :allow-empty="false"
                            placeholder
                            track-by="name"
                            openDirection="below"
                            label="name"
                            selectedLabel
                            selectLabel
                            deselectLabel
                            @open="repositionDropDown('languaget_mt')"
                          ></multiselect>
                          <!-- <input type="text" class="input-inline w-100" v-model="expertInfo.language_mt"> -->
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div class="table-cell">
                          LANGUAGE L2
                          <div class="f-10">Fluent</div>
                        </div>
                      </th>
                      <td>
                        <div class="table-cell">
                          <multiselect
                            ref="multiselect_language_fluent"
                            v-model="expertInfo.language_fluent"
                            :options="languages"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            :multiple="true"
                            placeholder
                            selectedLabel
                            selectLabel
                            deselectLabel
                            track-by="name"
                            openDirection="below"
                            label="name"
                            @open="repositionDropDown('language_fluent')"
                          ></multiselect>
                          <!-- <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertInfo.language_fluent.required && $v.expertInfo.language_fluent.$error">This field is required.</div> -->
                          <!-- <input type="text" class="input-inline w-100" v-model="expertInfo.language_fluent">  -->
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div class="table-cell">
                          LANGUAGE L3
                          <div class="f-10">Business level</div>
                        </div>
                      </th>
                      <td>
                        <div class="table-cell">
                          <multiselect
                            ref="multiselect_language_business"
                            v-model="expertInfo.language_business"
                            :options="languages"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            :multiple="true"
                            placeholder
                            track-by="name"
                            label="name"
                            openDirection="below"
                            @open="repositionDropDown('language_business')"
                          ></multiselect>
                          <!-- <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertInfo.language_business.required && $v.expertInfo.language_business.$error">This field is required.</div> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr class="m-y-30" />
              <h3 class="text-primary f-20 wt-300">
                <i class="mdi mdi-school mr-3"></i>Education &amp; Certification
              </h3>
              <education-certifications
                ref="expertEducationCertifications"
                :expert-education-certifications="expertEducationCertifications"
                :educationTypes="educationTypes"
              />
              <hr class="m-y-30" />
              <div class="text-right">
                <button
                  type="button"
                  :disabled="isSavingExpert"
                  class="btn btn-primary p-x-25"
                  @click="saveExpert"
                >
                  <span
                    v-show="isSavingExpert"
                    class="mdi mdi-spin mdi-loading"
                  ></span>
                  <span>Save</span>
                </button>
                <button
                  @click="cancel"
                  :disabled="isSavingExpert"
                  class="btn btn-light p-x-15"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="intro"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        class="modal fade"
      >
        <div
          role="document"
          class="modal-dialog modal-lg modal-dialog-centered"
        >
          <div class="modal-content">
            <div class="modal-body">
              <div class="d-flex">
                <div>
                  <i
                    class="mdi mdi-information-outline f-35 f-primary mr-3"
                    style="line-height: 1"
                  ></i>
                </div>
                <div>
                  Bayes' Company will introduce you to the expert by e-mail, in
                  which both of you will receive contact details.
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-light p-x-30"
              >
                Cancel
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-secondary p-x-30"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="message"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        class="modal fade"
      >
        <div
          role="document"
          class="modal-dialog modal-lg modal-dialog-centered"
        >
          <div class="modal-content position-relative">
            <div class="modal-close" data-dismiss="modal">
              <div class="mdi mdi-close"></div>
            </div>
            <div class="card card-body shadow p-md-45">
              <div class="row">
                <div class="col-auto">
                  <i
                    class="
                      mdi mdi-check-circle
                      text-success
                      f-35 f-primary
                      mr-3
                    "
                    style="line-height: 1"
                  ></i>
                </div>
                <div class="col">
                  <h1 class="h4 wt-300">Success</h1>
                  <div class="f-14 m-b-5">
                    <p><strong>Your changes have been updated.</strong></p>
                  </div>
                  <div class="m-b-5">
                    Following fields will only be updated after a review:
                  </div>
                  <div class="f-14 row row-cols-3">
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      INDUSTRY
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      SECTOR
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      SUBSECTOR
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      COMPANY
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      DEPARTMENT
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      FUNCTIONAL AREA
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      FUNCTION
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      TYPE
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      NAME
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      YEAR
                    </div>
                    <div
                      class="col border"
                      style="margin-bottom: -1px; margin-left: -1px"
                    >
                      LICENCE NUMBER
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StringMixin from "../../../../mixins/StringMixin.js";
import EducationCertifications from "./EducationCertifications";
import Experience from "./Experience";
import Industry from "./Industry";
import PhotoUpload from "../../../base/PhotoUpload";
import { required, requiredIf } from "vuelidate/lib/validators";
import MultiselectMixin from "./multiselectMixin";

import Multiselect from "vue-multiselect";
export default {
  props: {
    expert: {
      type: Object,
      required: true,
    },
    availablePer: {
      type: Object,
      required: true,
    },
    willingnessOption: {
      type: Object,
      required: true,
    },
    expertEducationCertifications: {
      type: Array,
      required: true,
    },
    educationTypes: {
      type: Array,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    expertExperiences: {
      type: Array,
      required: true,
    },

    expertIndustries: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    updateUrl: {
      type: String,
      required: true,
    },
    cancelUrl: {
      type: String,
      required: true,
    },
    dayRates: {
      type: Array,
      required: true,
    },
  },
  mixins: [StringMixin, MultiselectMixin],
  data() {
    return {
      expertInfo: JSON.parse(JSON.stringify(this.expert)),
      selectedPhoto: null,
      selectedResume: null,
      validExtensions: "pdf, doc, docx",
      isValidResume: true,
      hasSizeError: 0,
      hasExtensionError: 0,
      size: 2000000,
      limitSize: true,
      isSavingExpert: false,
    };
  },
  components: {
    EducationCertifications,
    Experience,
    Multiselect,
    Industry,
    PhotoUpload,
  },
  validations: {
    expertInfo: {
      available_per_id: {
        required,
      },
      country_id: {
        required,
      },
      first_name: {
        required,
      },
      language_mt: {
        required,
      },
      last_name: {
        required,
      },
      location: {
        required,
      },
      phone: {
        required,
      },
      target_day_rate: {
        required,
      },
      willingness_to_travel: {
        required,
      },
    },
    selectedResume: {
      required: requiredIf(function (nestedModel) {
        return this.expertInfo.resume === null;
      }),
    },
  },
  methods: {
    cancel() {
      location.href = this.cancelUrl;
    },
    saveExpert() {
      let self = this;
      self.isSavingExpert = true;
      self.$v.$touch();
      if (
        this.$v.$invalid ||
        !this.$refs.expertIndustries.isValidExpertIndustries() ||
        !this.$refs.expertExperiences.isValidExpertExperiences() ||
        !this.$refs.expertEducationCertifications.isValidExpertEducationCertifications() ||
        !this.checkValidResume() ||
        !this.$refs.expertPhoto.isValid()
      ) {
        self.isSavingExpert = false;
        self.$swal({
          icon: "error",
          title: "Something is wrong.",
          text: "Please check your input and try again.",
        });
        return;
      }

      let formData = new FormData();
      let expertIndustries = self.$refs.expertIndustries.getIndustries();
      let expertExperiences =
        self.$refs.expertExperiences.getExpertExperiences();
      let expertEducationCertifications =
        self.$refs.expertEducationCertifications.getEductionCertifications();

      let expertInfo = {
        first_name: this.expertInfo.first_name,
        last_name: this.expertInfo.last_name,
        country_id: this.expertInfo.country_id.three_letter_country_code,
        location: this.expertInfo.location,
        phone: this.expertInfo.phone,
        language_mt: this.expertInfo.language_mt.name,
        language_fluent:
          this.expertInfo.language_fluent === null
            ? ""
            : this.expertInfo.language_fluent
                .map(({ name }) => name)
                .join(", "),
        language_business:
          this.expertInfo.language_business === null
            ? ""
            : this.expertInfo.language_business
                .map(({ name }) => name)
                .join(", "),
        available_per: this.expertInfo.available_per_id,
        target_day_rate: this.expertInfo.target_day_rate,
        willingness_to_travel: this.expertInfo.willingness_to_travel,
      };

      formData.append("photo", this.selectedPhoto);
      formData.append("resume", this.selectedResume);
      formData.append("experts", JSON.stringify(expertInfo));
      formData.append("expertIndustries", JSON.stringify(expertIndustries));
      formData.append("expertExperiences", JSON.stringify(expertExperiences));
      formData.append(
        "expertEducationCertifications",
        JSON.stringify(expertEducationCertifications)
      );

      axios
        .post(self.updateUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          self
            .$swal({
              icon: "success",
              title: "Success",
              text: response.data.message,
              showConfirmButton: true,
              timer: 2000,
            })
            .then((result) => {
              location.reload();
              self.isSavingExpert = false;
            });
        })
        .catch(function (error) {
          let errorResponse = error.response;
          self.isSavingExpert = false;
          if (errorResponse.status == 422) {
            let errorMessage = errorResponse.data.errors[0];
            self.$swal({
              icon: "error",
              title: "Oops...",
              text: errorMessage,
            });
            return;
          }
          let errorMessage = errorResponse.data.message;
          self.$swal({
            icon: "error",
            title: "Oops...",
            text: errorMessage,
          });
          console.log(error);
        });
    },
    onResumeSelected(event) {
      if (event.target.files[0] !== undefined) {
        this.selectedResume = event.target.files[0];
        this.isValidResume = true;
        this.hasSizeError = 0;
        this.hasExtensionError = 0;

        let extension = this.selectedResume.name.split(".").pop();
        let validExtensions = this.validExtensions.replace(/\s/g, "");
        let validExtensionsArray = validExtensions.split(",");

        if (!validExtensionsArray.includes(extension)) {
          this.isValidResume = false;
          this.hasExtensionError = 1;
        }
        if (this.limitSize && this.selectedResume.size > this.size) {
          this.isValidResume = false;
          this.hasSizeError = 1;
        }
      }
    },
    checkValidResume() {
      return this.selectedResume === null ? true : this.isValidResume;
    },
    setAvailablePer(name, id) {
      this.expertInfo.available_per = name;
      this.expertInfo.available_per_id = id;
    },
  },
};
</script>
<style scoped>
.inactiveLink {
  pointer-events: none;
  cursor: default;
}
</style>