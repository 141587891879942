<template>
    <div>
        <section class="search-filter">
            <div :class=" showFeature ? 'container-lg-fluid container p-y-30 position-relative' :'container-full p-y-30 position-relative'" >
                <div :class="showFeature ? '' :''" id="autocomplete">
                    <template v-if="!showFeature && !hasFilter">
                        <h2 class="h2 text-primary wt-300">Experts</h2>
                        <p>Start by selecting one or more popular filters to start your Expert search:</p>
                    </template>
                    <template  v-if="!showFeature && hasFilter">
                        <h2 class="f-25 m-b-10 d-flex">Experts
                            <a class="btn btn-sm btn-link ml-auto o-8" href="javascript:void(0);" @click="resetFilter()">
                                <i class="mdi mdi-refresh"></i> Reset Filter
                            </a>
                        </h2>
                        <p>Start by selecting one or more popular filters to start your Expert search:</p>
                    </template>
                    <template v-if="showFeature">
                        <h2 class="h2 text-primary wt-300">Discover</h2>
                        <p>Select one or more filters to start your search for an expert:</p>
                    </template>
                        <template v-for="(filter,index) in filters">
                            <div class="bg-white shadow-sm p-10 rounded-lg" :id="filter.field_id+'_autocomplete'">
                                <div class="row gutter-10">
                                    <div class="col-xl-3 col-12 col-md-4 m-b-10 m-md-b-0">
                                        <input type="text" v-model="filter.label" class="form-control" disabled/>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center">
                                            <multiselect
                                                v-model="filter.values"
                                                :options="filter.entities"
                                                :searchable="true"
                                                :close-on-select="false"
                                                :clear-on-select="true"
                                                :multiple="true"
                                                :internal-search="!filter.asnychronous"
                                                :hide-selected="true"
                                                :loading="filter.isLoading"
                                                @search-change="asyncAutocomplete($event,index)"
                                                @open="showList($event,index)"
                                                selectedLabel
                                                selectLabel
                                                deselectLabel
                                                :placeholder="`Start Typing ${filter.label}`"
                                                track-by="name"
                                                openDirection="below"
                                                v-if="filter.asnychronous === 1"
                                            >
                                                <template slot="tag" slot-scope="{ option, remove }">
                                                <span class="multiselect__tag">
                                                  <span>{{option.name}}</span>
                                                  <i
                                                      aria-hidden="true"
                                                      tabindex="1"
                                                      @click="remove(option)"
                                                      class="multiselect__tag-icon"
                                                  ></i>
                                                </span>
                                                </template>
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ props.option.name }}</span>
                                                        <span class="option__small"></span>
                                                    </div>
                                                    <div class="search-breadcrumbs">{{ props.option.short_description }}
                                                    </div>
                                                </template>
                                                <span slot="noResult">No matching suggestions for {{ filter.label}}</span>
                                            </multiselect>
                                            <multiselect
                                                class="multiselect--tags"
                                                v-model="filter.values"
                                                :options="filter.entities"
                                                :searchable="true"
                                                :close-on-select="false"
                                                :clear-on-select="true"
                                                :multiple="true"
                                                :custom-label="searchString"
                                                selectedLabel
                                                :hide-selected="true"
                                                selectLabel
                                                deselectLabel
                                                :placeholder="`Start Typing ${filter.label}`"
                                                track-by="name"
                                                openDirection="below"
                                                label="name"
                                                v-else
                                            >
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc">
                                                        <span class="option__title">{{ props.option.name }}</span>
                                                        <span class="option__small"></span>
                                                    </div>
                                                    <div class="search-breadcrumbs">{{ props.option.short_description }}
                                                    </div>
                                                </template>
                                                <span slot="noResult">No matching suggestions for {{ filter.label}}</span>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-md-3 col-12 m-b-10 m-md-b-0">
                                        <div class="dropdown">
                                            <div>
                                                <multiselect
                                                    v-model="filter.operator_id"
                                                    :options="operators"
                                                    :searchable="false"
                                                    :preselect-first="true"
                                                    :close-on-select="true"
                                                    :show-labels="false"
                                                    :allow-empty="false"
                                                    placeholder
                                                    track-by="name"
                                                    openDirection="below"
                                                    label="name"
                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button
                                            @click="removeFilter(index)"
                                            class="btn btn-outline-danger border-white btn-icon"
                                        >
                                            <i class="mdi mdi-close"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-if="filters.length-1 !== index">
                                <i class="mdi mdi-plus"></i>
                            </div>
                        </template>
                        <div class="row gutter-10 m-y-20">
                            <div
                                :key="filterButton.field_id"
                                class="col-xl-auto col-lg-4 col-12 m-b-10 m-xl-b-0"
                                v-for="filterButton in filterButtons"
                                v-if="toggleFilterButton(filterButton.field_id)"
                            >
                                <button
                                    @click="addFilter(filterButton)"
                                    class="btn btn-light dropdown-toggle no-caret d-flex d-xl-inline-block w-100 f-14 f-xxl-16"
                                    type="button"
                                >
                                    <i class="mr-2" :class="filterButton.icon"></i>
                                    <span v-html="filterButton.label"></span>
                                    <i class="mdi mdi-plus ml-xl-2 ml-auto"></i>
                                </button>
                            </div>
                        </div>
                        <button class="btn btn-secondary btn-lg wt-500" @click="searchExperts">
                            <span v-show="isSearching" class="mdi mdi-spin mdi-loading"></span>
                            <span class="p-x-20">Search</span>
                        </button>
                </div>
            </div>
        </section>
        <!-- <div class="container-full p-y-30 position-relative">
            <a @click="setMap(true)" href="javascript:void(0)">{{ showMap ? 'Hide' : 'Show'}} Map 
                <span class="mdi mdi-arrow-down-drop-circle-outline" v-show="!showMap"></span>
                <span class="mdi mdi-arrow-up-drop-circle-outline" v-show="showMap"></span>
            </a>
        </div> -->
        <map-component :removeSelection="['RU']" @selected="loadLocation" :expertCountryData="expertCountryData" v-if="showMapAlways ? true : showMap && !showPage" />
        <result-component :isLoading="isSearching" ref="resultTable" :result="experts" :filter="queryParams" v-if="showPage || initailLoding"/>
        <!-- <feature-component v-if="showFeature"/> -->
        <!-- <search-help-component v-if="!showPage && !showFeature"/> -->
    </div>
</template>
<script>
    function filterObject(label, field_id, collection_id, placeholder) {
        this.field_id = field_id;
        this.operator_id = "";
        this.values = [];
        this.collection_id = collection_id;
        this.label = label;
        this.placeholder = placeholder;
    }

    import filterButtons from "../../../filterButtons";
    import ResultComponent from "./ResultComponent";
    import FeatureComponent from "./FeatureComponent";
    import SearchHelpComponent from "./SearchHelpComponent";
    import MapComponent from "./MapComponent";
    export default {
        components: {SearchHelpComponent, ResultComponent, FeatureComponent, MapComponent},
        props: {
            feature: {
                type: Boolean,
                default: true
            },
            showMapAlways: {
                type: Boolean,
                default: false
            },
            expertCountryData:{
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        data() {
            return {
                filters: [],
                filterButtons,
                showMap : true,
                operators: [
                    {
                        name: "Includes Any",
                        operator_id: "includes"
                    },
                    {
                        name: "Includes All",
                        operator_id: "includes_all"
                    }
                ],
                result: [],
                isSearching: false,
                initailLoding : false
            };
        },
        methods: {
            loadLocation(event){
                let self = this;
                let locationFilter = _.find(self.filters,['collection_id','location']);
                if(locationFilter === undefined){
                    let locationFilterButton = _.find(self.filterButtons,['field_id','location']);

                    if(locationFilterButton !== undefined){
                        if (event.hasInfo == 1) {
                            let values = [event.countryInfo];
                            self.addFilter(locationFilterButton,values);
                        }
                        
                    }
                }else{
                    if (event.hasInfo == 1) {
                        let hasCountry = _.find(locationFilter.values,['country_name',event.countryInfo.country_name]);
                        if(hasCountry === undefined){

                            locationFilter.values.push(event.countryInfo)
                        }
                        else{

                            let countryIndex = _.findIndex(locationFilter.values,['country_name',event.countryInfo.country_name]);
                            locationFilter.values.splice(countryIndex,1);
                        }    
                    }
                }

                let container = self.$el.querySelector("#autocomplete");
                container.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            },
            setMap(toggle,flag=true){
                if(toggle===true){
                    this.showMap = !this.showMap;
                    return;
                }

                this.showMap = flag;
            },
            searchString(obj) {
                let str = "";
                _.forEach(obj, function (value, key) {
                    str += " " + value;
                });
                return str;
            },
            addFilter(filter,values = []) {
                this.filters.push({
                    label: filter.label,
                    field_id: filter.field_id,
                    collection_id: filter.field_id,
                    placeholder: filter.placeholder,
                    values,
                    operator_id: this.operators[0],
                    loading: false,
                    asnychronous: filter.asnychronous,
                    entities: filter.entities,
                    isLoading: false,
                    order: filter.order
                });
                this.filters = _.orderBy(this.filters, ["order"], ["asc"]);
            },
            showList(query, index) {
                let filterRow = this.filters[index];
                if (filterRow.entities.length == 0) {
                    this.asyncAutocomplete(query, index);
                }
            },
            asyncAutocomplete: _.throttle(function (query, index) {
                let filterRow = this.filters[index];
                filterRow.isLoading = true;
                let self = this;
                axios
                    .get("/data/autocompletes", {
                        params: {
                            query: query === null ? "" : query,
                            collection_id: filterRow.collection_id,
                            limit: 500
                        }
                    })
                    .then(function (response) {
                        filterRow.entities = response.data.entities;
                        // filterRow.entities = response.data.entities.map(entity=>{
                        //     for (const key in entity) {
                        //         if(typeof (entity[key]) === 'string' && key !== 'category') {
                        //             entity[key] = self.titleCase(entity[key]);
                        //         }
                        //     }
                        //     return entity;
                        // });
                        filterRow.isLoading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 600),
            titleCase: (valueString) => {
                let sentence = valueString.toString().toLowerCase().split(" ");
                for(let i = 0; i< sentence.length; i++){
                    sentence[i] = sentence[i].charAt(0).toUpperCase() + sentence[i].slice(1);
                }
                return sentence.join(" ");
            },
            removeFilter(index) {
                this.filters.splice(index, 1);
            },
            toggleFilterButton(field_id) {
                return _.find(this.filters, ["field_id", field_id]) === undefined;
            },
            prepareData(filters) {
                return _.map(filters, value => {
                    return {
                        field_id: value.field_id,
                        collection_id: value.collection_id,
                        operator_id: value.operator_id.operator_id,
                        values: value.values
                    };
                });
            },
            searchExperts() {
                let self = this;
                self.isSearching = true;
                axios({
                    method: "GET",
                    header: {
                        "Content-type": "application/json"
                    },
                    url: `${location.origin}` + `/search/expert`,
                    params: self.prepareData(self.filters)
                })
                    .then(response => {

                        if(self.showPage){
                            self.$refs.resultTable.resetSort();
                        }
                        localStorage.removeItem('search');
                        localStorage.removeItem('result');

                        localStorage.setItem('search', JSON.stringify(self.filters));
                        localStorage.setItem('result', JSON.stringify(response));
                        if (self.showFeature) {

                            window.location.href = `${location.origin}` + `/experts`;
                            return false;
                        }
                        self.result = response;
                        self.setMap(false,false)

                    })
                    .catch(e => {
                        localStorage.removeItem('search');
                        localStorage.removeItem('result');
                        if (self.showFeature) {
                            localStorage.setItem('search', JSON.stringify(self.filters));
                            localStorage.setItem('result', JSON.stringify([]));
                            window.location.href = `${location.origin}` + `/experts`;
                            return false;
                        }
                        self.result = [];
                    })
                    .then(function () {
                        if (self.showFeature) {
                            return false
                        }
                        self.isSearching = false;
                    });
            },
            resetFilter: function () {
                this.filters = [];
                this.searchExperts();
            }
        },
        computed: {
            showPage: function () {
                try {
                    return Array.isArray(this.result) ? false : this.result.length === 0 ? false : this.result.data.data.length >= 0;
                } catch (e) {
                    return true;
                }
            },
            experts: function () {
                return this.result;
            },
            queryParams: function () {
                return this.prepareData(this.filters);
            },
            showFeature: function () {
                return this.feature;
            },
            hasFilter: function () {
                return Array.isArray(this.filters) && this.filters.hasOwnProperty('length') ? this.filters.length > 0 : false;
            }
        },
        created() {
            if(!this.showFeature) {
                // if(localStorage.getItem('search') === null){
                //     this.initailLoding = true;
                //     this.searchExperts();
                //     return;
                // }
                this.setMap(false,true)

                let searchFilter = localStorage.getItem('search');
                let result = localStorage.getItem('result');
                searchFilter = JSON.parse(searchFilter);
                result = JSON.parse(result);
                if (searchFilter) {
                    this.filters = searchFilter;
                }
                if(result) {
                    this.result = result;
                }
            }
        }

    };
</script>
