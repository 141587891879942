<template>
    <section class="search-result p-md-y-45 p-y-25 container-full">
        <div class="m-b-15 row">
            <div class="col-12 col-md m-b-10 m-b-md-0">Showing {{ shownRecords}} of {{ totalRecords }} Results</div>
            <div class="col-12 col-md-auto">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="connectedExpertFilterFlag" @change="addConnectedExpertFilter" class="custom-control-input" id="connectedExpert">
                    <label class="custom-control-label" for="connectedExpert">Connected experts</label>
                </div>
            </div>
        </div>
        <div class="p-45 text-center d-none"><i class="mdi mdi-spin mdi-loading text-primary f-60"></i><br> <span class="wt-200 f-30">Loading.. Please wait</span></div>
        <div class="search-result-table-container">
            <table class="table-listing table-search-result f-14">
                <thead>
                    <tr>
                        <th class="text-primary wt-700" width="200">
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col-auto">
                                    <button class="mr-1 btn btn-sm btn-light" @click="orderColumn('first_name')"><i
                                        :class="iconType(   'first_name')"></i>
                                    </button>
                                </div>
                                <div class="col">
                                    <input v-model="searchString.name" class="input-title" size="4" type="text"
                                           placeholder="Name">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col wt-500"><span class="invisible nowrap wt-400">Industry, Sector, Company</span>
                                    <span class="input-title" style="border: none;">Industry, Sector, Company   </span>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col wt-500"><span class="invisible nowrap wt-400">Department, Function, Topic</span>
                                    <span class="input-title" style="border: none;"> Department, Function, Topic </span>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col-auto">
                                    <button class="mr-1 btn btn-sm btn-light" @click="orderColumn('location')"><i
                                        :class="iconType('location')"></i>
                                    </button>
                                </div>
                                <div class="col wt-700"><span class="invisible nowrap wt-400">Location</span>
                                    <input v-model="searchString.location" class="input-title" size="4" type="text"
                                        placeholder="Location">
                                </div>
                            </div>
                        </th>
                        <th width="170">
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col-auto">
                                    <button class="mr-1 btn btn-sm btn-light" @click="orderColumn('language_mt')"><i
                                        :class="iconType('language_mt')"></i>
                                    </button>
                                </div>
                                <div class="col wt-700"><span class="invisible nowrap wt-400">Language</span>
                                    <input v-model="searchString.language" class="input-title" size="4" type="text"
                                        placeholder="Langauage">
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="row flex-nowrap table-title-search gutter-5">
                                <div class="col wt-500"><span class="invisible nowrap wt-400">Education & Certification</span>
                                    <span class="input-title" style="border: none;"> Education & Certification   </span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr class="loading-row">
                    <td colspan="6" v-if="isSearching || isLoading" style="text-align: center;"><div class="p-45 text-center"><i class="mdi mdi-spin mdi-loading text-primary f-60"></i><br> <span class="wt-200 f-30">Loading.. Please wait</span></div></td>
                </tr>
                <template v-for="(expert,expertIndex) in expertsArray" v-if="!isSearching && !isLoading">
                    <tr style="cursor: pointer" @click="goToLink('/expert/' + expert.slug)">
                        <td data-title="Name" width="11%">
                            <div class="d-flex align-items-center">
                                <a :href="'/expert/' + expert.slug" class="expert-img-small position-relative">
                                    <vue-load-image>
                                        <img class="rounded-circle mr-2" width="25" height="25" alt="" style="object-fit: cover;" slot="image" :src="expert.photo"/>
                                        <img class="rounded-circle mr-2" width="25" height="25" alt="" style="object-fit: cover;" slot="preloader" src="/img/loader.gif"/>
                                        <div slot="error">Image loading failed.</div>
                                    </vue-load-image>
                                    <!-- <img class="rounded-circle mr-2" src="https://randomuser.me/api/portraits/men/1.jpg" width="25" alt=""> -->
                                    <div class="online-status" :class="kebabCase(expert.available_per)" data-toggle="tooltip" :title="expert.available_per" data-placement="bottom"></div>
                                </a>
                                <div class="row gutter-5 flex-nowrap align-items-center">
                                    <div class="col"><a :href="'/expert/' + expert.slug" class="expert-name">{{ expert.first_name}}</a></div>
                                    <div class="col-auto" v-if="expert.is_introduced"><div class="mdi mdi-link-variant" data-toggle="tooltip" title="Connected" data-placement="bottom"></div></div>  
                                </div>
                            </div>
                        </td>
                        <td data-title="Industry & Company" width="40%">
                            <list :lists="sortArrayWithSearch(expert.industry_companies)" :search-filters="experts.data.searchFilters"></list>
                        </td>
                        <td data-title="Experience" width="25%">
                            <list :lists="sortArrayWithSearch(expert.experiences)" :search-filters="experts.data.searchFilters"></list>
                        </td>
                        <td data-title="Locations" v-html="transformResultString(expert.locations)"></td>
                        <td data-title="Languages" v-html="transformResultString(expert.languages)"></td>
                        <td data-title="Educations" width="10%" v-html="transformResultString(expert.educations.join(' | '))"></td>
                    </tr>
                </template>
                <template v-if="(!expertsArray || showNoMatch) && (!isSearching && !isLoading)">
                    <tr>
                        <td colspan="6" style="text-align: center;">
                            {{ 'No matched experts' }}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <nav class="p-b-30">
            <pagination ref="pagination"  @isSearching="emitSearch" v-model="experts" :appends="expertResultFilters" :current_page=current_page
                        :last_page=last_page
                        :path="path"/>
        </nav>
    </section>

</template>

<script>
    import Pagination from "../../base/Pagination";
    import List from "../../base/List";
    import VueLoadImage from '../../base/ImageItem'
    import StringMixin from "../../../mixins/StringMixin.js"

    function pluck(array, key) {
        return array.map(o => o[key]);
    }

    const initialExpertResultFilters = {
                    orderBy: '',
                    sort: 'asc',
                    searchBy: {}
                };

    export default {
        name: "ResultComponent",
        mixins:[StringMixin],
        components: {
            Pagination,
            List,
            VueLoadImage
        },
        props: ['result', 'filter' , 'isLoading'],
        data() {
            return {
                expertResultFilters: Object.assign(Object.create(initialExpertResultFilters),this.filter),
                experts: [],
                searchString: {},
                isSearching: false,
                connectedExpertFilterFlag : false,
            }
        },
        methods: {
            addConnectedExpertFilter(){
                let self = this;
                this.isSearching = true;
                Object.assign(this.expertResultFilters, {
                    connected_experts :  self.connectedExpertFilterFlag
                });
                let url = `${this.path}`;
                
                axios({
                    method: 'GET',
                    header: {
                        'Content-type': 'application/json',
                    },
                    url,
                    params: self.expertResultFilters,
                })
                .then(response => {
                    self.experts = response;
                    
                }).catch(error => {
                console.log(error);
                }).then(function(){
                    self.isSearching = false;
                });
            },
            goToLink(link){
                location.href = link;
            },
            sortArrayWithSearch(sortingArr){
                let sortedArr = [];
                this.experts.data.searchFilters.forEach((searchElement,searchIndex) => {
                    sortingArr.forEach((sortingArrElement,sortingArrIndex) => {
                        if (sortingArrElement.includes(searchElement)) {
                            sortedArr.push(sortingArrElement);
                        }
                    });
                });
                return _.union(sortedArr,sortingArr);
            },
            transformResultString(resultString){
                this.experts.data.searchFilters.forEach(el =>{
                    resultString = resultString.replace(el,`<b>${el}</b>`)
                });
                return resultString;
            },
            resetSort(){
                this.expertResultFilters.orderBy = '';
                this.expertResultFilters.connected_experts = false;
                this.connectedExpertFilterFlag = false;
            },
            emitSearch(e) {
                this.isSearching = e === '1';
            },
            filterBy(filtering) {
                let self = this;

                    // this.experts.data.data = this.result.data.data.filter(record=>{
                    //      let filterResult =true
                    //      for (const key in filtering) {
                    //        if(record[key]) {
                    //             filterResult =  record[key].toString().toLocaleLowerCase().includes(filtering[key].toString().toLowerCase())
                    //             if(filterResult === false) return false;
                    //        }
                    //      }
                    //     return filterResult
                    // });
                this.isSearching = true;
                let url = `${this.path}`;
                Object.assign(this.expertResultFilters, {
                    searchBy :  JSON.stringify(filtering)
                });
                axios({
                    method: 'GET',
                    header: {
                        'Content-type': 'application/json',
                    },
                    url,
                    params: self.expertResultFilters,
                })
                    .then(response => {
                        self.experts = response;
                    }).catch(error => {
                    console.log(error);
                }).then(function(){
                    self.isSearching = false;
                });
            },
            orderResultSetCell(itemsArray){
                let item_order = this.experts.data.searchFilters;

                return itemsArray.sort( function (a, b) {
                    return item_order.includes(a) - item_order.includes(b);
                });
            },
            orderColumn(column) {
                let self = this;
                this.$refs.pagination.setUrl(1);
                this.isSearching = true;
                let sort = 'asc';
                if (this.expertResultFilters.orderBy == column && this.expertResultFilters.sort == 'asc') {
                    sort = 'desc';
                }

                // let url = this.searchString ? `${this.path}?sort=${sort}&orderBy=${column}&searchBy=${JSON.stringify(this.searchString)}` : `${this.path}?sort=${sort}&orderBy=${column}`;

                Object.assign(this.expertResultFilters, {
                    sort,
                    orderBy : column
                });

                axios({
                    method: 'GET',
                    header: {
                        'Content-type': 'application/json',
                    },
                    url: self.path,
                    params: self.expertResultFilters,
                })
                .then(response => {
                    self.experts = response;
                }).catch(error => {
                    console.error(error);
                }).then(function(){
                    self.isSearching = false;
                });
            },
            iconType(columnName) {
                return this.expertResultFilters.orderBy == columnName ? (this.expertResultFilters.sort == 'asc' ? "mdi mdi-sort-ascending" : "mdi mdi-sort-descending") : "mdi mdi-sort-variant";
            }
        },
        computed: {

            showAllResult(className){
                let elements = document.getElementsByClassName(className);
                if (elements.style.display === "none") {
                    elements.style.display = "block";
                } else {
                    elements.style.display = "none";
                }
            },
            current_page: function () {
                try {
                    return Array.isArray(this.experts) ? 0 : this.experts.data.meta.current_page;
                }catch (e) {
                    return 0;
                }
            }
            ,
            last_page: function () {
                try {
                    return Array.isArray(this.experts) ? 0 : this.experts.data.meta.last_page;
                }catch (e) {
                    return 0;
                }
            }
            ,
            path: function () {
                try {
                    return Array.isArray(this.experts) ? "" : this.experts.data.meta.path;
                }catch (e) {
                    return "";
                }
            }
            ,
            expertsArray: {
                get: function () {
                    try {
                        return Array.isArray(this.experts) ? [] : this.experts.data.data;
                    }catch (e) {
                        return [];
                    }
                },

                set: function (newValue) {
                    this.experts.data.data = newValue;

                }
            },
            totalRecords: function () {
                try {
                    return Array.isArray(this.experts) ? 0 : this.experts.data.meta.total;
                }catch (e) {
                    return 0;
                }
            }
            ,
            shownRecords: function () {
                try {
                    return Array.isArray(this.experts) ? 0 : this.experts.data.meta.to;
                } catch (e) {
                    return 0
                }

            },
            showNoMatch: function () {
                if(this.result) {
                    return Array.isArray(this.expertsArray) && this.expertsArray.length === 0;
                }
               return false;
            }
        }
        ,
        watch: {
            experts: function(n,o){
                this.expertsArray = this.experts.data.data;
            },
            result: function (n, o) {
                this.experts = JSON.parse(JSON.stringify(this.result));
                this.searchString = {};
            },
            searchString: {
                handler(n, o) {
                    let self = this;
                    if (Object.keys(n).length === 0) return false;
                    setTimeout(function () {
                        self.filterBy(self.searchString);
                    }, 1000);
                },
                deep: true
            },
            filter: {
                handler(n, o) {
                    this.expertResultFilters = Object.assign(Object.create(initialExpertResultFilters),n);
                },
                deep: true
            },
        },
        created() {
            this.experts = JSON.parse(JSON.stringify(this.result));
            this.searchString = {};
            
        }
    }
</script>

<style scoped>

</style>
