<template>
    <a :class=" `${addBtnClass} m-t-15`" :href="start?'javascript:void(0);':'#intro'"
       :data-target="start?'javascript:void(0)':'#intro'" data-toggle="modal">
        <span class="m-x-10">
            <i :class="`mdi ${ showIcon } mr-2`"></i>
            {{ showBtnText }}
        </span>
    </a>
</template>

<script>
    export default {
        name: "GetExpertIntroductionComponent",
        props: ['value', 'slug', 'updatedData', 'proceed'],
        data() {
            return {
                introduce: false,
            }
        },
        methods: {
            getIntroduction: function () {
                let self = this;
                if (!this.introduce && this.start) {
                    axios(`${location.origin}` + `/expert/${this.slug}/introduce`).then(response => {
                        let statusCode = response.data.status;
                        let responseData = response.data;
                        switch (statusCode) {
                            case 'ok':
                                self.$emit('input', true);
                                break;
                            case 'credit-finished':
                                self.$emit('credit-finished', responseData);
                                break;
                            case 'already-introduced':
                                self.$emit('already-introduced', responseData);
                                break;
                            default:
                                self.$emit('input', false);
                                break;
                        }
                    }).catch(e => self.$emit('input', false));
                }
            }
        },
        computed: {
            introduced: function () {
                return this.introduce;
            },
            showIcon: function () {
                return this.proceed ? this.updatedData ? 'mdi-account-check' : 'mdi-email' : 'mdi-human-greeting';
            },
            addBtnClass: function () {
                return this.proceed ? this.updatedData ? 'btn btn-outline-success inactiveLink' : 'btn btn-outline-warning inactiveLink' : 'btn btn-secondary';
            },
            start: function () {
                return this.proceed;
            },
            showBtnText: function () {
                return this.proceed ? this.updatedData ? 'Already Introduced' : 'Introduction Sent' : 'Please introduce us';
            }
        },
        watch: {
            proceed: {
                immediate: true,
                handler(n, o) {
                    if (n) {
                        if (!this.updatedData) {
                            this.getIntroduction()
                        }
                    }
                }
            }
        }
    }
</script>
