<template>
    <div class="table-overflow">
        <table class="table-detail f-14">
            <tbody>
                <tr class="action-row">
                    <th>
                        <button @click="addColumn" class="action-btn action-add">
                            <i class="mdi mdi-plus"></i>
                        </button>
                    </th>
                    <td v-for="(educationCertification,index) in expertEducationCertificationsInfo" >

                        <button v-if="educationCertification.removed == 0" @click="removeColumn(index,1)" class="action-btn action-delete">
                        <i class="mdi mdi-trash-can"></i>
                        </button>
                        <button v-else @click="removeColumn(index,0)" class="action-btn action-delete">
                        <i class="mdi mdi-window-close"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">TYPE</div>
                    </th>
                    <td v-for="(educationCertification,index) in expertEducationCertificationsInfo" :style="educationCertification.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <select :class="!$v.expertEducationCertificationsInfo.$each.$iter[index].education_type.required && $v.expertEducationCertificationsInfo.$each.$iter[index].education_type.$error ? 'is-invalid' : ''" class="input-inline" style="width:100%" v-model="educationCertification.education_type" >
                                <option v-for="educationType in educationTypes" :value="educationType.id">{{ educationType.name }}</option>
                            </select>
                            <div class="form-text f-12 invalid-feedback" v-if="!$v.expertEducationCertificationsInfo.$each.$iter[index].education_type.required && $v.expertEducationCertificationsInfo.$each.$iter[index].education_type.$error">This field is required.</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">NAME</div>
                    </th>
                    <td v-for="(educationCertification,index) in expertEducationCertificationsInfo" :style="educationCertification.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <input :class="!$v.expertEducationCertificationsInfo.$each.$iter[index].name.required && $v.expertEducationCertificationsInfo.$each.$iter[index].name.$error ? 'is-invalid' : ''" type="text" class="input-inline w-100" v-model="educationCertification.name">
                            <div class="form-text f-12 invalid-feedback" v-if="!$v.expertEducationCertificationsInfo.$each.$iter[index].name.required && $v.expertEducationCertificationsInfo.$each.$iter[index].name.$error">This field is required.</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">YEAR</div>
                    </th>
                    <td v-for="(educationCertification,index) in expertEducationCertificationsInfo" :style="educationCertification.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell"
                        >
                            <input :class="$v.expertEducationCertificationsInfo.$each.$iter[index].year.$error ? 'is-invalid' : ''"   type="text" class="input-inline w-100" v-model="educationCertification.year">

                            <div class="form-text f-12 invalid-feedback" v-show="!$v.expertEducationCertificationsInfo.$each.$iter[index].year.year && $v.expertEducationCertificationsInfo.$each.$iter[index].year.$error">This field is invalid.</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">LICENCE NUMBER</div>
                    </th>
                    <td v-for="(educationCertification,index) in expertEducationCertificationsInfo" :style="educationCertification.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <input type="text" class="input-inline w-100" v-model="educationCertification.license_number">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>  
</template>
<script>
import { required, minLength, between, minValue, decimal } from 'vuelidate/lib/validators'
import { helpers } from 'vuelidate/lib/validators'
// const year = (value) => {
//         console.log(value,'here')
//         return  true
//     }
const year = helpers.withParams(
  { type: 'year' },
  (value) => {
        // console.log(value.year,!helpers.req(value.year) || moment(value.year,"YYYY").isValid(),!helpers.req(value.year) , moment(value.year,"YYYY").isValid())
        return !helpers.req(value) || moment(value,"YYYY",true).isValid()
  }
)

const newColumn = {
    id : 0,
    education_type : '',
    name : '',
    year : '',
    license_number : '',
    removed: 0
}
export default {
    name: "EducationCertifications",
    props:{

        expertEducationCertifications:{
            type: Array,
            required: true
        },
        educationTypes:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            expertEducationCertificationsInfo : this.prepareExpertEducationCertificationsInfo(JSON.parse(JSON.stringify(this.expertEducationCertifications)))
        }
    },
    validations: {
        expertEducationCertificationsInfo :{
            $each: {
                education_type: {
                    required,
                },
                name: {
                    required,
                },
                year:{
                    year,
                }
            }
        }
    },
    methods: {
        removeColumn(index,flag) {
            this.expertEducationCertificationsInfo[index].removed = flag;
            if(this.expertEducationCertificationsInfo[index].id == 0){
                this.expertEducationCertificationsInfo.splice(index,1);
            }
        },
        addColumn(){
            this.$v.$reset();
            this.expertEducationCertificationsInfo.unshift(Object.assign({},newColumn))
        },
        getEductionCertifications(){
            let expertEducationCertificationsInfo = JSON.parse(JSON.stringify(this.expertEducationCertificationsInfo));
            expertEducationCertificationsInfo.forEach(expertEducationCertification => {
                delete expertEducationCertification.type_name;

            });
            return expertEducationCertificationsInfo;
        },
        prepareExpertEducationCertificationsInfo(expertEducationCertifications) {
            expertEducationCertifications.forEach(expertEducationCertification => {
                expertEducationCertification.removed = 0;
            });

            return expertEducationCertifications;
        },
        isValidExpertEducationCertifications(){
            this.$v.$touch();
            let isValidForm = true;
            if (this.$v.$invalid) {
                isValidForm = false;
            }

            return isValidForm;
        }
    },
}
</script>