<template>
    <div class="search-suggestions">
        <div class="container-fluid p-y-30 position-relative">
            <div class="p-md-x-30">
                <p>Start with a search template:</p>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-20 wt-300">Find experts by:</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-map-marker"></i>Location</div>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-translate"></i>Language</div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-20 wt-300">Find experts by:</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-translate"></i>Language</div>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-school"></i>Education and certification
                                        <p></p>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-20 wt-300">Find experts by:</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-translate"></i>Language</div>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-school"></i>Experience
                                        <p></p>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                </div>
                <hr class="m-y-30">
                <p>Start with a pre-populated search:</p>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-16 wt-400">Western US Security Acquired Companies</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-account"></i>40</div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-16 wt-400">Investors Active in Bothell, Washington</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-account"></i>20</div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-16 wt-400">Grand Rapids Companies</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-account"></i>5</div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30"><a class="card shadow-sm f-14 link-block h-100" href="#">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col">
                                    <h5 class="text-primary f-16 wt-400">Google Alumni Founded Companies</h5>
                                    <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-account"></i>20</div>
                                </div>
                                <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                            </div>
                        </div></a></div>
                    <div class="col-12 col-md-6 col-xl-4 col-xxl-3 m-b-15  m-md-b-30">
                        <a class="card shadow-sm f-14 link-block" href="#">
                            <div class="card-body">
                                <div class="row no-gutters align-items-center">
                                    <div class="col">
                                        <h5 class="text-primary f-16 wt-400">Southeast Asia Late Stage Venture Investors</h5>
                                        <div class="d-flex m-t-5"><i class="m-r-5 mdi o-7 mdi-account"></i>5</div>
                                    </div>
                                    <div class="col-auto"><i class="mdi mdi-chevron-right f-30 o-2"></i></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchHelpComponent"
    }
</script>

<style scoped>

</style>
