export default {
    methods: {
        repositionDropDown(refName) {
            let ref = this.$refs[`multiselect_${refName}`]
            if(Array.isArray(this.$refs[`multiselect_${refName}`])){
                ref = ref[0];
            }
            const {top, height, width, left}  = ref.$refs.tags.getBoundingClientRect();
            ref.$refs.list.style.width = `${width}px`;
        },
    },
}