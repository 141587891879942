<template>
    <section class="features-list p-y-50">
        <div class="container container-lg-fluid">
            <h3 class="wt-300 m-b-50">Features</h3>
            <div class="row">
                <div class="col-12 col-md-6 col-lg m-b-40 feature-grid">
                    <h5 class="f-16 text-primary feature-title">Access BAYES expert network</h5>
                    <div class="feature-img"><img class="img-feature m-b-20" src="img/img-1.svg" alt=""></div>
                    <div class="feature-desc">
                        <p>Get access to the exclusive network of BAYES of 1500 experts</p>
                        <a href="#">Learn more <i class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg m-b-40 feature-grid">
                    <h5 class="f-16 text-primary feature-title">Find experts</h5>
                    <div class="feature-img"><img class="img-feature m-b-20" src="img/img-2.svg" alt=""></div>
                    <div class="feature-desc"><p>Discover professionals based on specific experience and competences for your project</p><a href="#">Learn more <i class="mdi mdi-arrow-right"></i></a></div>
                </div>
                <div class="col-12 col-md-6 col-lg m-b-40 feature-grid">
                    <h5 class="f-16 text-primary feature-title">Get introduced to each other</h5>
                    <div class="feature-img"><img class="img-feature m-b-20" src="img/img-3.svg" alt=""></div>
                    <div class="feature-desc"><p>Get direct introduced to each other without a third party in between</p><a href="#">Learn more <i class="mdi mdi-arrow-right"></i></a></div>
                </div>
                <div class="col-12 col-md-6 col-lg m-b-40 feature-grid">
                    <h5 class="f-16 text-primary feature-title">Work with each other</h5>
                    <div class="feature-img"><img class="img-feature m-b-20" src="img/img-4.svg" alt=""></div>
                    <div class="feature-desc"><p>Let us know if you decide to work with each other</p><a href="#">Learn more <i class="mdi mdi-arrow-right"></i></a></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FeatureComponent"
    }
</script>

<style scoped>

</style>
