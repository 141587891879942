<template>
    <ul class="pagination justify-content-center f-12 f-md-16">
        <template v-if="last_page>0">
            <li class="page-item" >
                <a class="page-link" href="javascript:void(0);"
                   v-on:click="setUrl((current_page===1?1:current_page-1))">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <template v-for="page in Pages">
                <li class="page-item " :class="(!isNaN(page)) && page===current_page?'active':''">
                    <a class="page-link" href="javascript:void(0);" v-on:click="setUrl(page)"> {{page}}</a>
                </li>
            </template>
            <li class="page-item" >
                <a class="page-link" href="javascript:void(0);" v-on:click="setUrl(current_page<last_page ? current_page + 1 :'...')">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            current_page: {
                type: Number,
                default: 0
            }, last_page: {
                type: Number,
                default: 0
            }, path: {
                type: String,
                default: `${location.origin}/search/expert`
            },
            appends:{
                type: Object,
                default: function () { return {} }
            },
            value: {},
        },
        data() {
            return {
                Pages: [],
            }
        },
        methods: {
            setUrl: function (page) {
                let self = this;
                if (isNaN(page) || page === this.current_page) return;
                this.$emit('isSearching','1');
                let url = `${this.path}?page=${page}`;
                axios({
                    method: 'GET',
                    header: {
                        'Content-type': 'application/json',
                    },
                    url,
                    params: this.appends,
                })
                    .then(response => {
                        self.$emit('input', response);
                    }).catch(error => {
                    console.log(error);
                }).then(function(){
                    self.$emit('isSearching','0');
                });

            },
            setPages: function () {
                let list = [];
                let loop_start = this.current_page;
                let loop_end = this.last_page;
                let addEnd = false;
                if (this.last_page <= 7) {
                    loop_start = 1;
                    loop_end = this.last_page;
                }
                if (this.last_page > 7 && this.current_page < 6) {
                    loop_start = 1;
                    loop_end = 6;
                    addEnd = true;
                }
                if (this.last_page > 7 && this.current_page >= 6 && this.last_page - this.current_page > 5) {
                    list.push(1);
                    list.push('...');
                    loop_start = this.current_page - 1;
                    loop_end = this.current_page + 2;
                    addEnd = true;
                }
                if (this.last_page - this.current_page <= 5 && this.last_page > 7 && this.current_page >= 6) {
                    list.push(1);
                    list.push('...');
                    loop_start = this.last_page - 6;
                    loop_end = this.last_page;
                }
                for (let i = loop_start; i <= loop_end; i++) {
                    list.push(i);
                }
                if (addEnd) {
                    list.push('...');
                    list.push(this.last_page);
                }
                return list;
            }
        },
        watch: {
            current_page: function (newValue, oldValue) {
                this.Pages = this.setPages();
            },
            last_page: function (newValue, oldValue) {
                this.Pages = this.setPages();
            }
        },
        created() {
            this.Pages = this.setPages();
        }

    }
</script>

<style scoped>

</style>
