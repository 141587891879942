<template>
    <div class="table-overflow">
        <table class="table-detail f-14">
            <tbody>
                <tr class="action-row">
                    <th>
                        <button @click="addColumn" class="action-btn action-add">
                            <i class="mdi mdi-plus"></i>
                        </button>
                    </th>
                    <td v-for="(experience,index) in expertExperiencesInfo">

                        <button v-if="experience.removed == 0" @click="removeColumn(index,1)" class="action-btn action-delete">
                        <i class="mdi mdi-trash-can"></i>
                        </button>
                        <button v-else @click="removeColumn(index,0)" class="action-btn action-delete">
                        <i class="mdi mdi-window-close"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">
                            DEPARTMENT
                            <div class="f-10">Main department</div>
                        </div>
                    </th>
                    <td v-for="(experience,index) in expertExperiencesInfo" :class="!$v.expertExperiencesInfo.$each.$iter[index].department.required && $v.expertExperiencesInfo.$each.$iter[index].department.$error ? 'invalid' : ''" :style="experience.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <multiselect
                                :ref="'multiselect_department_'+index"
                                v-model="experience.department"
                                :options="experience.departments"
                                :searchable="true"
                                :internal-search="false"
                                :hide-selected="true"
                                @input="fetchFunctionalAreaWithoutThrottle('',experience.department,index,1)"
                                @search-change="fetchDepartment($event,index)"
                                @open="fetchDepartment($event,index)"
                                :loading="experience.isLoadingDepartment"
                                selectedLabel
                                selectLabel
                                deselectLabel
                                placeholder
                                openDirection="below"
                                :show-labels="false"
                            >
                                <span slot="noResult">No matching suggestions for department.</span>
                                <span slot="noOptions">Type to search.</span>

                            </multiselect>
                            <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertExperiencesInfo.$each.$iter[index].department.required && $v.expertExperiencesInfo.$each.$iter[index].department.$error">This field is required.</div>
                            <!-- <input type="text" class="input-inline w-100" v-model="experience.department" data-toggle="tooltip"> -->
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">
                            FUNCTIONAL AREA
                            <div class="f-10">Functional area within department
                            </div>
                        </div>
                    </th>
                    <td v-for="(experience,index) in expertExperiencesInfo" :class="!$v.expertExperiencesInfo.$each.$iter[index].functional_area.required && $v.expertExperiencesInfo.$each.$iter[index].functional_area.$error ? 'invalid' : ''" :style="experience.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <multiselect
                                :ref="'multiselect_functional_area_'+index"
                                v-model="experience.functional_area"
                                :options="experience.functionalAreas"
                                :searchable="true"
                                :internal-search="false"
                                :hide-selected="true"
                                @search-change="fetchFunctionalArea($event,experience.department,index)"
                                @input="fetchTopic('',experience.department,experience.functional_area,index,1)"
                                @open="fetchFunctionalArea($event,experience.department,index)"
                                :loading="experience.isLoadingDepartment"
                                selectedLabel
                                selectLabel
                                deselectLabel
                                placeholder
                                :preselect-first="true"
                                openDirection="below"
                                :show-labels="false"
                            >
                                <span slot="noResult">No matching suggestions for functional area.</span>
                                <span slot="noOptions">Please select department.</span>

                            </multiselect>
                            <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertExperiencesInfo.$each.$iter[index].functional_area.required && $v.expertExperiencesInfo.$each.$iter[index].functional_area.$error">This field is required.</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <div class="table-cell">
                            TOPIC
                            <div class="f-10">Specific name of function</div>
                        </div>
                    </th>
                    <td v-for="(experience,index) in expertExperiencesInfo" :class="!$v.expertExperiencesInfo.$each.$iter[index].topic.required && $v.expertExperiencesInfo.$each.$iter[index].topic.$error ? 'invalid' : ''" :style="experience.removed==1 ? 'background-color: #fae7eb;' : ''">
                        <div class="table-cell">
                            <multiselect
                                :ref="'multiselect_topic_'+index"
                                v-model="experience.topic"
                                :options="experience.topics"
                                :searchable="true"
                                :internal-search="false"
                                :hide-selected="true"
                                @search-change="fetchTopic($event,experience.department,experience.functional_area,index)"
                                @open="fetchTopic($event,experience.department,experience.functional_area,index)"
                                :loading="experience.isLoadingTopic"
                                selectedLabel
                                selectLabel
                                deselectLabel
                                placeholder
                                :preselect-first="true"
                                openDirection="below"
                                :show-labels="false"
                            >
                                <span slot="noResult">No matching suggestions for topic.</span>
                                <span slot="noOptions">Please select department and functional area.</span>

                            </multiselect>
                            <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertExperiencesInfo.$each.$iter[index].topic.required && $v.expertExperiencesInfo.$each.$iter[index].topic.$error">This field is required.</div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { required, minLength, between } from 'vuelidate/lib/validators'
import MultiselectMixin from './multiselectMixin'

const newColumn = {
                    id:0,
                    experience_id:0,
                    department:"",
                    functional_area:"",
                    topic:"",
                    isLoadingDepartment : false,
                    isLoadingFunctionalArea: false,
                    isLoadingTopic: false,
                    departments: [],
                    functionalAreas:[],
                    topics: [],
                    removed : 0
                }
export default {
    props:{
        expertExperiences:{
            type: Array,
            required: true
        },
    },
    data() {
        return {
            expertExperiencesInfo : this.prepareExpertExperienceInfo(JSON.parse(JSON.stringify(this.expertExperiences)))
        }
    },
    mixins:[
        MultiselectMixin
    ],
    validations: {
        expertExperiencesInfo :{
            $each: {
                department: {
                    required,
                },
                functional_area: {
                    required,
                },
                topic: {
                    required,
                }
            }
        }
    },
    methods: {
        prepareExpertExperienceInfo(data){
            data.forEach(element => {
                element.isLoadingDepartment = false;
                element.isLoadingFunctionalArea = false;
                element.isLoadingTopic = false;

                element.departments = [];
                element.functionalAreas = [];
                element.topics = [];
                element.removed = 0;
            });

            return data;
        },
        removeColumn(index,flag) {
            this.expertExperiencesInfo[index].removed = flag;
            if(this.expertExperiencesInfo[index].id == 0){
                this.expertExperiencesInfo.splice(index,1);
            }
        },
        addColumn(){
            this.$v.$reset();
            this.expertExperiencesInfo.unshift(Object.assign({},newColumn))
        },
        fetchDepartment:_.throttle(function (query,index){
            let self = this;
            self.repositionDropDown(`department_${index}`);

            if(!_.isEmpty(query) || self.expertExperiencesInfo[index].departments.length == 0){
                self.expertExperiencesInfo[index].isLoadingDepartment = true;
                axios.post('/api/experience-department',{
                    query
                })
                .then(function (response) {
                    self.expertExperiencesInfo[index].departments = response.data.data
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    self.expertExperiencesInfo[index].isLoadingDepartment = false;
                }); 
            } 
        },800),
        fetchFunctionalArea:_.throttle(function (query,department,index){
            this.fetchFunctionalAreaWithoutThrottle(query,department,index)
        },800),

        fetchFunctionalAreaWithoutThrottle(query,department,index,force=0){
            let self = this;
            self.repositionDropDown(`functional_area_${index}`);

            if((!_.isEmpty(query) || self.expertExperiencesInfo[index].functionalAreas.length == 0 || force == 1) && !!department ){
                self.expertExperiencesInfo[index].isLoadingFunctionalArea = true;
                axios.post('/api/experience-functional-area',{
                    query,
                    department
                })
                .then(function (response) {
                    self.expertExperiencesInfo[index].topics = [];
                    let functionalAreas = response.data.data;
                    self.expertExperiencesInfo[index].functionalAreas = functionalAreas;

                    if(force == 1){
                        self.expertExperiencesInfo[index].functional_area = null;
                        self.expertExperiencesInfo[index].topic = null;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    self.expertExperiencesInfo[index].isLoadingFunctionalArea = false;
                }); 
            } 
        },
        fetchTopic:_.throttle(function (query,department,functional_area,index,force=0){
            let self = this;
            self.repositionDropDown(`topic_${index}`);
            if((!_.isEmpty(query) || self.expertExperiencesInfo[index].topics.length == 0 || force == 1) && !!department && !!functional_area ){
                self.expertExperiencesInfo[index].isLoadingTopic = true;
                axios.post('/api/experience-topic',{
                    query,
                    department,
                    functional_area
                })
                .then(function (response) {
                    let topics = response.data.data;
                    self.expertExperiencesInfo[index].topics = topics;

                    if(force == 1){
                        self.expertExperiencesInfo[index].topic = null;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    self.expertExperiencesInfo[index].isLoadingTopic = false;
                }); 
            } 
        },800),

        getExpertExperiences(){
            let expertExperiencesInfo = JSON.parse(JSON.stringify(this.expertExperiencesInfo));
            expertExperiencesInfo.forEach(experience => {
                delete experience.isLoadingDepartment;
                delete experience.isLoadingFunctionalArea;
                delete experience.isLoadingTopic;

                delete experience.departments;
                delete experience.functionalAreas;
                delete experience.topics;

            });
            return expertExperiencesInfo;
        },
        isValidExpertExperiences(){
            this.$v.$touch();
            let isValidForm = true;
            if (this.$v.$invalid) {
                isValidForm = false;
            }

            return isValidForm;
        }
    
    },
}
</script>