const languages = [
    {
        name: "Afar"
    },
    {
        name: "Abkhaz"
    },
    {
        name: "Avestan"
    },
    {
        name: "Afrikaans"
    },
    {
        name: "Akan"
    },
    {
        name: "Amharic"
    },
    {
        name: "Aragonese"
    },
    {
        name: "Arabic"
    },
    {
        name: "Assamese"
    },
    {
        name: "Avaric"
    },
    {
        name: "Aymara"
    },
    {
        name: "Azerbaijani"
    },
    {
        name: "South Azerbaijani"
    },
    {
        name: "Bashkir"
    },
    {
        name: "Belarusian"
    },
    {
        name: "Bulgarian"
    },
    {
        name: "Bihari"
    },
    {
        name: "Bislama"
    },
    {
        name: "Bambara"
    },
    {
        name: "Bengali; Bangla"
    },
    {
        name: "Tibetan Standard"
    },
    {
        name: "Breton"
    },
    {
        name: "Bosnian"
    },
    {
        name: "Catalan; Valencian"
    },
    {
        name: "Chechen"
    },
    {
        name: "Chamorro"
    },
    {
        name: "Corsican"
    },
    {
        name: "Cree"
    },
    {
        name: "Czech"
    },
    {
        name: "Old Church Slavonic"
    },
    {
        name: "Chuvash"
    },
    {
        name: "Welsh"
    },
    {
        name: "Danish"
    },
    {
        name: "German"
    },
    {
        name: "Divehi; Dhivehi; Maldivian;"
    },
    {
        name: "Dzongkha"
    },
    {
        name: "Ewe"
    },
    {
        name: "Greek"
    },
    {
        name: "English"
    },
    {
        name: "Esperanto"
    },
    {
        name: "Spanish"
    },
    {
        name: "Estonian"
    },
    {
        name: "Basque"
    },
    {
        name: "Persian (Farsi)"
    },
    {
        name: "Fula; Fulah; Pulaar; Pular"
    },
    {
        name: "Finnish"
    },
    {
        name: "Fijian"
    },
    {
        name: "Faroese"
    },
    {
        name: "French"
    },
    {
        name: "Western Frisian"
    },
    {
        name: "Irish"
    },
    {
        name: "Scottish Gaelic; Gaelic"
    },
    {
        name: "Galician"
    },
    {
        name: "Guaraní"
    },
    {
        name: "Gujarati"
    },
    {
        name: "Manx"
    },
    {
        name: "Hausa"
    },
    {
        name: "Hebrew (modern)"
    },
    {
        name: "Hindi"
    },
    {
        name: "Hiri Motu"
    },
    {
        name: "Croatian"
    },
    {
        name: "Haitian; Haitian Creole"
    },
    {
        name: "Hungarian"
    },
    {
        name: "Armenian"
    },
    {
        name: "Herero"
    },
    {
        name: "Interlingua"
    },
    {
        name: "Indonesian"
    },
    {
        name: "Interlingue"
    },
    {
        name: "Igbo"
    },
    {
        name: "Nuosu"
    },
    {
        name: "Inupiaq"
    },
    {
        name: "Ido"
    },
    {
        name: "Icelandic"
    },
    {
        name: "Italian"
    },
    {
        name: "Inuktitut"
    },
    {
        name: "Japanese"
    },
    {
        name: "Javanese"
    },
    {
        name: "Georgian"
    },
    {
        name: "Kongo"
    },
    {
        name: "Kikuyu"
    },
    {
        name: "Kwanyama"
    },
    {
        name: "Kazakh"
    },
    {
        name: "Kalaallisut"
    },
    {
        name: "Khmer"
    },
    {
        name: "Kannada"
    },
    {
        name: "Korean"
    },
    {
        name: "Kanuri"
    },
    {
        name: "Kashmiri"
    },
    {
        name: "Kurdish"
    },
    {
        name: "Komi"
    },
    {
        name: "Cornish"
    },
    {
        name: "Kyrgyz"
    },
    {
        name: "Latin"
    },
    {
        name: "Luxembourgish"
    },
    {
        name: "Ganda"
    },
    {
        name: "Limburgish"
    },
    {
        name: "Lingala"
    },
    {
        name: "Lao"
    },
    {
        name: "Lithuanian"
    },
    {
        name: "Luba-Katanga"
    },
    {
        name: "Latvian"
    },
    {
        name: "Malagasy"
    },
    {
        name: "Marshallese"
    },
    {
        name: "Māori"
    },
    {
        name: "Macedonian"
    },
    {
        name: "Malayalam"
    },
    {
        name: "Mongolian"
    },
    {
        name: "Marathi (Marāṭhī)"
    },
    {
        name: "Malay"
    },
    {
        name: "Maltese"
    },
    {
        name: "Burmese"
    },
    {
        name: "Nauru"
    },
    {
        name: "Norwegian Bokmål"
    },
    {
        name: "North Ndebele"
    },
    {
        name: "Nepali"
    },
    {
        name: "Ndonga"
    },
    {
        name: "Dutch"
    },
    {
        name: "Norwegian Nynorsk"
    },
    {
        name: "Norwegian"
    },
    {
        name: "South Ndebele"
    },
    {
        name: "Navajo"
    },
    {
        name: "Chichewa; Chewa; Nyanja"
    },
    {
        name: "Occitan"
    },
    {
        name: "Ojibwe"
    },
    {
        name: "Oromo"
    },
    {
        name: "Oriya"
    },
    {
        name: "Ossetian"
    },
    {
        name: "Panjabi"
    },
    {
        name: "Pāli"
    },
    {
        name: "Polish"
    },
    {
        name: "Pashto"
    },
    {
        name: "Portuguese"
    },
    {
        name: "Quechua"
    },
    {
        name: "Romansh"
    },
    {
        name: "Kirundi"
    },
    {
        name: "Romanian"
    },
    {
        name: "Russian"
    },
    {
        name: "Kinyarwanda"
    },
    {
        name: "Sanskrit (Saṁskṛta)"
    },
    {
        name: "Sardinian"
    },
    {
        name: "Sindhi"
    },
    {
        name: "Northern Sami"
    },
    {
        name: "Sango"
    },
    {
        name: "Sinhala"
    },
    {
        name: "Slovak"
    },
    {
        name: "Slovene"
    },
    {
        name: "Samoan"
    },
    {
        name: "Shona"
    },
    {
        name: "Somali"
    },
    {
        name: "Albanian"
    },
    {
        name: "Serbian"
    },
    {
        name: "Swati"
    },
    {
        name: "Southern Sotho"
    },
    {
        name: "Sundanese"
    },
    {
        name: "Swedish"
    },
    {
        name: "Swahili"
    },
    {
        name: "Tamil"
    },
    {
        name: "Telugu"
    },
    {
        name: "Tajik"
    },
    {
        name: "Thai"
    },
    {
        name: "Tigrinya"
    },
    {
        name: "Turkmen"
    },
    {
        name: "Tagalog"
    },
    {
        name: "Tswana"
    },
    {
        name: "Tonga (Tonga Islands)"
    },
    {
        name: "Turkish"
    },
    {
        name: "Tsonga"
    },
    {
        name: "Tatar"
    },
    {
        name: "Twi"
    },
    {
        name: "Tahitian"
    },
    {
        name: "Uyghur"
    },
    {
        name: "Ukrainian"
    },
    {
        name: "Urdu"
    },
    {
        name: "Uzbek"
    },
    {
        name: "Venda"
    },
    {
        name: "Vietnamese"
    },
    {
        name: "Volapük"
    },
    {
        name: "Walloon"
    },
    {
        name: "Wolof"
    },
    {
        name: "Xhosa"
    },
    {
        name: "Yiddish"
    },
    {
        name: "Yoruba"
    },
    {
        name: "Zhuang"
    },
    {
        name: "Chinese"
    },
    {
        name: "Zulu"
    }
];
export default languages;
