<template>
    <section>
        <div class="image-upload-wrap">
        <vue-load-image>
            <img slot="image"  :src="photo" width="144" alt="" class="rounded-lg image-profile">
            <!-- <img class="rounded-lg mr-2" width="144" alt="" slot="image" :src="profile.expert.photo"/> -->
            <img class="rounded-lg mr-2" width="144" alt="" slot="preloader" src="/img/loader.gif"/>
            <div slot="error">Image loading failed.</div>
        </vue-load-image>
        <i class="mdi mdi-pencil f-24"></i>
        <div class="hover-text f-12 rounded-lg ">{{label}}</div>
        </div>
        <input type="file" ref="photo" @change="onPhotoSelected" class="position-absolute o-0" :class="isValidPhoto==0?'is-invalid':''" style="left:0; right:0;width:100%">
        
        
        <div class="form-text f-12 invalid-feedback" v-show="hasExtensionError==1">Invalid photo extension. Only {{validExtensions}} are accepted.</div>

        <div class="form-text f-12 invalid-feedback" v-show="hasSizeError==1">The photo size must be less than or equal to {{(size/1e+6).toFixed(1)}} MB.</div>
    </section>
</template>
<script>
import VueLoadImage from './ImageItem'

export default {
    props: {
        preview :{
            type: String,
            required: true
        },
        width:{
            type: Number,
            default: 144
        },
        label:{
            type: String,
            default: "Change image"
        },
        validExtensions:{
            type: String,
            default: "jpeg, jpg, png"
        },
        limitSize:{
            type: Boolean,
            default : true
        },
        size:{
            type: Number,
            default :2000000
        }
    },
    components:{
        VueLoadImage
    },
    data() {
        return {
            selectedPhoto : null,
            photo: this.preview,
            isValidPhoto : true,
            hasSizeError : 0,
            hasExtensionError : 0
        }
    },
    methods: {
        onPhotoSelected(event){
            if(event.target.files[0] !== undefined){
                let selectedPhoto = event.target.files[0]
                this.isValidPhoto = true;
                this.hasSizeError = 0;
                this.hasExtensionError = 0;

                if(this.limitSize && selectedPhoto.size > this.size){
                    this.isValidPhoto = false;
                    this.hasSizeError = 1;
                }

                let extension = selectedPhoto.name.split('.').pop();
                let validExtensions = this.validExtensions.replace(/\s/g, '');
                let validExtensionsArray = validExtensions.split(',');

                if(!validExtensionsArray.includes(extension)){
                    this.isValidPhoto = false;
                    this.hasExtensionError = 1;
                }

                if(this.hasExtensionError == 0){
                    this.selectedPhoto = selectedPhoto;
                    this.photo= URL.createObjectURL(this.selectedPhoto );
                }
                this.$emit('input',this.selectedPhoto)
            }
        },
        isValid(){
            return this.selectedPhoto === null ? true : this.isValidPhoto;
        }
    },
}
</script>