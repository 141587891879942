<template>
  <section>
    <form @submit.prevent="saveForm">
      <div id="errorDiv" v-show="errorResponse.length > 0">
          <ul>
              <li v-for="(error,errorIndex) in errorResponse"><code>{{ error }}</code></li>
          </ul>
      </div>
      <div class="row m-b-20">
        <div class="col-12 col-lg">
          <label class="control-label f-12 text-primary wt-500">First name</label>
          <input
            class="form-control "
            :class="{ 'is-invalid': $v.userInfo.firstName.$error }"
            v-model.trim="userInfo.firstName"
            :disabled="isSaving"
          />
          <div
            class="invalid-feedback"
            v-show="!$v.userInfo.firstName.required"
          >This field is required.</div>
        </div>
        <div class="col-12 col-lg">
          <label class="control-label f-12 text-primary wt-500">Last name</label>
          <input
            class="form-control "
            :class="{ 'is-invalid': $v.userInfo.lastName.$error }"
            v-model.trim="userInfo.lastName"
            :disabled="isSaving"
          />
          <div
            class="invalid-feedback"
            v-show="!$v.userInfo.lastName.required"
          >This field is required.</div>
        </div>
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">Email/Username</label>
        <input
          class="form-control"
          :class="{ 'is-invalid': $v.userInfo.email.$error }"
          v-model.trim="userInfo.email"
          :disabled="isSaving"
        />
        <div class="form-text f-12 text-muted">Please add company email address here</div>
        <div class="invalid-feedback">Please provide a valid email address.</div>
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">Password</label>
        <input
          class="form-control"
          type="password"
          :class="{ 'is-invalid': $v.userInfo.password.$error }"
          v-model="userInfo.password"
          :disabled="isSaving"
        />
        <div class="form-text f-12 text-muted">Passwords must be at least 10 characters in length</div>
        <div
          class="invalid-feedback"
          v-show="!$v.userInfo.password.required"
        >This field is required.</div>
        <div
          class="invalid-feedback"
          v-show="!$v.userInfo.password.minLength"
        >Minimum length is {{$v.userInfo.password.$params.minLength.min}} characters.</div>
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">Phone</label>
        <input class="form-control" v-model.trim="userInfo.phone" :disabled="isSaving" />
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">Role/Function</label>
        <select
          class="custom-select"
          :class="{ 'is-invalid': $v.userInfo.roleFunction.$error }"
          v-model="userInfo.roleFunction"
          :disabled="isSaving"
        >
          <option value>Please select</option>
          <option
            :value="roleFunction.id"
            v-for="roleFunction in roleFunctions"
            :key="roleFunction.id"
          >{{ roleFunction.name }}</option>
        </select>
        <div
          class="invalid-feedback"
          v-show="!$v.userInfo.roleFunction.required"
        >This field is required.</div>
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">Company Name</label>
        <input
          class="form-control "
          :class="{ 'is-invalid': $v.userInfo.companyName.$error }"
          v-model.trim="userInfo.companyName"
          :disabled="isSaving"
        />
        <div class="invalid-feedback">This field is required.</div>
      </div>
      <div class="m-b-20">
        <label class="control-label f-12 text-primary wt-500">What kind of company?</label>
        <select
          class="custom-select"
          :class="{ 'is-invalid': $v.userInfo.companyType.$error }"
          v-model="userInfo.companyType"
          :disabled="isSaving"
        >
          <option value>Please select</option>
          <option
            :value="companyType.id"
            v-for="companyType in companyTypes"
            :key="companyType.id"
          >{{ companyType.name }}</option>
        </select>
        <div
          class="invalid-feedback"
          v-show="!$v.userInfo.companyType.required"
        >This field is required.</div>
      </div>
  
      <div class="form-group custom-control custom-checkbox">
        <input v-model="userInfo.termsOptIn" class="custom-control-input" required :class="{ 'is-invalid': $v.userInfo.termsOptIn.$error }" type="checkbox" true-value="1" false-value="1" name="termsOptIn" id="termsOptIn" data-qa-selector="new_user_accept_terms_checkbox">
        <label style="color: inherit" class="custom-control-label f-12" for="termsOptIn">I accept the <a target="_blank" :href="generalTermsLink">General Terms &amp; Conditions of Service</a>
        </label>
        <div
          class="invalid-feedback"
          v-show="!$v.userInfo.termsOptIn.checked"
        >This field is required.
        </div>
      </div>
      <div class="m-t-40">
        <button  class="btn btn-secondary btn-lg" type="submit" :disabled="isSaving">
          <span class="mdi mdi-loading mdi-spin" v-show="isSaving"></span>
          <span class="p-x-10">
            Submit
            <i class="mdi mdi-arrow-right m-l-10"></i>
          </span>
        </button>
      </div>
    </form>
    <alert-modal-component ref="alertModal"/>
  </section>
</template>
<script>
import AlertModalComponent from "../../AlertModalComponent"
import dropdownLists from "./dropdown-lists";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isSaving : false,
      errorResponse : [],
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        companyName: "",
        companyType: "",
        roleFunction: "",
        phone: "",
        termsOptIn : 0,
      }
    };
  },
  props:{
    companyTypes :{
      type : Array,
      required : true
    },
    roleFunctions :{
      type : Array,
      required : true
    },
    redirectLink:{
      type: String,
      default: '/signin',
    },
    generalTermsLink:{
      type: String,
      default: '/terms',
    },
  },
  components :{
      AlertModalComponent
  },
  methods: {
    saveForm(formEvent) {
        
      let self = this;
      self.isSaving = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        self.isSaving = false;
        return;
      }

      axios
        .post("/signup", this.userInfo)
        .then(function(response) {
          window.location.href = self.redirectLink;
        })
        .catch(function(error) {
          console.log(error);
          let errorResponse = error.response;
          if(errorResponse.status == 422){
            self.errorResponse = errorResponse.data.errors;
            setTimeout(() => {
                document.getElementById('errorDiv').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});;
            }, 0);
            return;
          }
          self.$refs.alertModal.showModal();
        }).then(function(){
            self.isSaving = false;
        });
    }
  },
  validations: {
    userInfo: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(10)
      },
      companyName: {
        required,
      },
      companyType: {
        required
      },
      roleFunction: {
        required
      },
      termsOptIn:{
        required,
        checked: value => value == 1
      }
    }
  }
};
</script>