import languages from "./languages";
const filterButtons = [{
        icon: "mdi mdi-domain",
        label: "Industry, Sector, Company",
        field_id: "industry_company",
        placeholder: "",
        entities: [],
        asnychronous: 1,
        order: 1
    },
    {
        icon: "mdi mdi-star",
        label: "Department, Function, Topic",
        field_id: "experience",
        placeholder: "",
        entities: [],
        asnychronous: 1,
        order: 2
    },
    {
        icon: "mdi mdi-map-marker",
        label: "Location",
        field_id: "location",
        placeholder: "",
        entities: [],
        asnychronous: 1,
        order: 3
    },
    {
        icon: "mdi mdi-translate",
        label: "Language",
        field_id: "language",
        placeholder: "",
        entities: languages,
        asnychronous: 0,
        order: 4
    },
    {
        icon: "mdi mdi-school",
        label: "Education & Certification",
        field_id: "education_certification",
        placeholder: "",
        entities: [],
        asnychronous: 1,
        order: 5
    }
];
export default filterButtons;
