<template>
  <div class="table-overflow">
    <table class="table-detail f-14">
      <tbody>
        <tr class="action-row">
          <th>
            <button @click="addColumn" class="action-btn action-add">
              <i class="mdi mdi-plus"></i>
            </button>
          </th>
          <td v-for="(industry,index) in expertIndustriesInfo">
            <button v-if="industry.removed == 0" @click="removeColumn(index,1)" class="action-btn action-delete">
              <i class="mdi mdi-trash-can"></i>
            </button>
            <button v-else @click="removeColumn(index,0)" class="action-btn action-delete">
              <i class="mdi mdi-window-close"></i>
            </button>
          </td>
        </tr>
        <tr>
          <th>
            <div class="table-cell">
              INDUSTRY
              <div class="f-10">High Level Industry</div>
            </div>
          </th>
          <td v-for="(industry,index) in expertIndustriesInfo" :style="industry.removed==1 ? 'background-color: #fae7eb;' : ''">
            <div class="table-cell" :class="!$v.expertIndustriesInfo.$each.$iter[index].industry.required && $v.expertIndustriesInfo.$each.$iter[index].industry.$error ? 'invalid' : ''">
              <multiselect
                :ref="'multiselect_industry_'+index"
                v-model="industry.industry"
                :options="industry.industries"
                :searchable="true"
                :internal-search="false"
                :hide-selected="true"
                @search-change="fetchIndustries($event,index)"
                @input="fetchSectors('',industry.industry.id,index,1)"
                @open="fetchIndustries($event,index)"
                :loading="industry.isLoadingIndustry"
                selectedLabel
                selectLabel
                deselectLabel
                placeholder
                :preselect-first="true"
                openDirection="below"
                label="name"
              >
                <span slot="noResult">No matching suggestions for industry.</span>
                <span slot="noOptions">Type to search.</span>
              </multiselect>
              <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertIndustriesInfo.$each.$iter[index].industry.required && $v.expertIndustriesInfo.$each.$iter[index].industry.$error">This field is required.</div>
              <!-- <input
                type="text"
                class="input-inline w-100"
                value="SERVICES OTHER CONSUMER"
                
              />-->
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="table-cell">
              SECTOR
              <div class="f-10">Sector within industry</div>
            </div>
          </th>
          <td v-for="(industry,index) in expertIndustriesInfo" :style="industry.removed==1 ? 'background-color: #fae7eb;' : ''" :class="!$v.expertIndustriesInfo.$each.$iter[index].sector.required && $v.expertIndustriesInfo.$each.$iter[index].sector.$error ? 'invalid' : ''">
            <div class="table-cell">
              <multiselect
                :ref="'multiselect_sector_'+index"
                v-model="industry.sector"
                :options="industry.sectors"
                :searchable="true"
                :internal-search="false"
                :hide-selected="true"
                @search-change="fetchSectors($event,industry.industry.id,index)"
                @input="fetchSubsectors('',industry.sector.id,index,1)"
                @open="fetchSectors($event,industry.industry.id,index)"
                :loading="industry.isLoadingIndustry"
                selectedLabel
                selectLabel
                deselectLabel
                placeholder
                :preselect-first="true"
                openDirection="below"
                label="name"
              >
                <span slot="noResult">No matching suggestions for sector.</span>
                <span slot="noOptions">{{ industry.industry=='' ? 'Please select industry.' : 'Type to search.' }}</span>
              </multiselect>
              <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertIndustriesInfo.$each.$iter[index].sector.required && $v.expertIndustriesInfo.$each.$iter[index].sector.$error">This field is required.</div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="table-cell">
              SUBSECTOR
              <div class="f-10">Specific activity within industry</div>
            </div>
          </th>
          <td v-for="(industry,index) in expertIndustriesInfo" :style="industry.removed==1 ? 'background-color: #fae7eb;' : ''" :class="!$v.expertIndustriesInfo.$each.$iter[index].subsector.required && $v.expertIndustriesInfo.$each.$iter[index].subsector.$error ? 'invalid' : ''">
            <div class="table-cell">
              <multiselect
                :ref="'multiselect_subsector_'+index"
                v-model="industry.subsector"
                :options="industry.subsectors"
                :searchable="true"
                :internal-search="false"
                :hide-selected="true"
                @search-change="fetchSubsectors($event,industry.sector.id,index)"
                @open="fetchSubsectors($event,industry.sector,index)"
                :loading="industry.isLoadingIndustry"
                selectedLabel
                selectLabel
                deselectLabel
                placeholder
                :preselect-first="true"
                openDirection="below"
                label="name"
              >
                <span slot="noResult">No matching suggestions for sub-sector.</span>
                <span slot="noOptions">{{ industry.industry=='' || industry.sector == '' ? 'Please select industry and sector.' : 'Type to search.' }}</span>
              </multiselect>
              <div class="form-text f-12 is-invalid-feedback" v-if="!$v.expertIndustriesInfo.$each.$iter[index].subsector.required && $v.expertIndustriesInfo.$each.$iter[index].subsector.$error">This field is required.</div>
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <div class="table-cell">
              COMPANY
              <div class="f-10">Company name</div>
            </div>
          </th>
          <td v-for="(industry,index) in expertIndustriesInfo" :style="industry.removed==1 ? 'background-color: #fae7eb;' : ''">
            <div class="table-cell">
              <input
                type="text"
                class="input-inline w-100 input-md"
                :class="!$v.expertIndustriesInfo.$each.$iter[index].company.required && $v.expertIndustriesInfo.$each.$iter[index].company.$error ? 'is-invalid' : ''"
                v-model="industry.company"
              />
              <div class="form-text f-12 invalid-feedback" v-if="!$v.expertIndustriesInfo.$each.$iter[index].company.required">This field is required.</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { required, minLength, between } from 'vuelidate/lib/validators'
import MultiselectMixin from './multiselectMixin'
const newColumn = {
  company: "",
  id: 0,
  industries: [],
  industry: "",
  isLoadingIndustry: false,
  isLoadingSector: false,
  isLoadingSubsector: false,
  removed: 0,
  sector: "",
  sectors: [],
  subsector: "",
  subsectors: []
};

export default {
  props: {
    expertIndustries: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      expertIndustriesInfo: this.prepareIndustryInfo(
        JSON.parse(JSON.stringify(this.expertIndustries))
      )
    };
  },
  validations: {
    expertIndustriesInfo :{
      $each: {
        industry: {
          required,
        },
        sector: {
          required,
        },
        subsector: {
          required,
        },
        company:{
          required
        }
      }
    }
  },
  mixins:[
    MultiselectMixin
  ],
  methods: {
    prepareIndustryInfo(industries) {
      industries.forEach(industry => {
        industry.isLoadingIndustry = false;
        industry.isLoadingSector = false;
        industry.isLoadingSubsector = false;

        industry.industries = [];
        industry.sectors = [];
        industry.subsectors = [];
        industry.removed = 0;
      });

      return industries;
    },
    removeColumn(index,flag) {
      this.expertIndustriesInfo[index].removed = flag;
      if(this.expertIndustriesInfo[index].id == 0){
          this.expertIndustriesInfo.splice(index,1);
      }
    },
    addColumn() {
      this.$v.$reset();
      this.expertIndustriesInfo.unshift(Object.assign({}, newColumn));
    },
    fetchIndustries: _.throttle(function(query, index, force = 0) {
      let self = this;
      self.repositionDropDown(`industry_${index}`);
      if ( !_.isEmpty(query) || self.expertIndustriesInfo[index].industries.length == 0) {
        self.expertIndustriesInfo[index].isLoadingIndustry = true;
        axios
          .post("/api/industries", {
            query
          })
          .then(function(response) {
            self.expertIndustriesInfo[index].industries = response.data.data;
          })
          .catch(function(error) {
            console.log(error);
          })
          .then(function() {
            self.expertIndustriesInfo[index].isLoadingIndustry = false;
          });
      }
    }, 800),
    fetchSectors: _.throttle(function(query, industry_id, index, force = 0) {
      let self = this;
      self.repositionDropDown(`sector_${index}`);
      self.expertIndustriesInfo[index].subsectors = [];
      
      if ( (!_.isEmpty(query) || self.expertIndustriesInfo[index].sectors.length == 0 || force == 1) && !!industry_id ) {
        self.expertIndustriesInfo[index].isLoadingSector = true;
        axios
          .post("/api/sectors", {
            query,
            industry_id
          })
          .then(function(response) {
            let sectors = response.data.data;
            self.expertIndustriesInfo[index].sectors = sectors;
            if (force == 1) {
              self.expertIndustriesInfo[index].sector = '';
              self.expertIndustriesInfo[index].subsector = '';
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .then(function() {
            self.expertIndustriesInfo[index].isLoadingSector = false;
          });
      }
    }, 800),
    fetchSubsectors: _.throttle(function(query, sector_id, index, force = 0) {
      let self = this;
      self.repositionDropDown(`subsector_${index}`);
      if ( (!_.isEmpty(query) || self.expertIndustriesInfo[index].subsectors.length == 0 || force == 1) && !!sector_id ) {
        self.expertIndustriesInfo[index].isLoadingSubsector = true;
        axios
          .post("/api/subsectors", {
            query,
            sector_id
          })
          .then(function(response) {
            let subsectors = response.data.data;
            self.expertIndustriesInfo[index].subsectors = subsectors;
            if (force == 1) {
              self.expertIndustriesInfo[index].subsector = '';
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .then(function() {
            self.expertIndustriesInfo[index].isLoadingSubsector = false;
          });
      }
    }, 800),
    getIndustries() {
      let expertIndustriesInfo = JSON.parse(JSON.stringify(this.expertIndustriesInfo))
      expertIndustriesInfo.forEach(industry => {
        delete industry.isLoadingIndustry;
        delete industry.isLoadingSector;
        delete industry.isLoadingSubsector;

        delete industry.industries;
        delete industry.sectors;
        delete industry.subsectors;

      });
      return expertIndustriesInfo;
    },
    isValidExpertIndustries(){
      this.$v.$touch();
      let isValidForm = true;
      if (this.$v.$invalid) {
        isValidForm = false;
      }

      return isValidForm;
    }
  }
};
</script>