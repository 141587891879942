<template>
    <div>
        <div class="row no-gutters">
            <div class="col-md-auto col-12">
                <section class="profile-detail">
                    <div class="p-xl-y-30 p-xl-x-45 p-y-30 p-x-15">
                        <a class="btn btn-transparent btn-sm m-b-10" href="/experts">
                            <i class="mdi mdi-arrow-left mr-2"></i>
                            Back to the list
                        </a>
                        <br>
                        <div class="m-b-25 image-upload-wrap">
                            <vue-load-image>
                                <img class="rounded-lg mr-2 image-profile" width="144" alt="" slot="image" :src="profile.expert.photo"/>
                                <img class="rounded-lg mr-2 image-profile" width="144" alt="" slot="preloader" src="/img/loader.gif"/>
                                <div slot="error">Image loading failed.</div>
                            </vue-load-image>
                        </div>
                        <div class="m-b-25">
                            <div class="profile-data profile-code f-14" v-if="profile.admin">#{{profile.expert.id}}
                            </div>
                            <h2 class="profile-data profile-name profile-code text-primary f-23 wt-400 mt-0 m-b-15">
                                <span class="profile-firstname">{{profile.expert.first_name}} </span>
                                <template v-if="access">
                                    <span class="profile-lasttname">{{ accessMore.expert.last_name}}</span>
                                </template>
                                <template v-else>
                                    <span class="blur-text "><span class="hidden-text">{{ replaceText }}</span></span>
                                </template>
                                
                            </h2>
                            <div class="profile-data f-14" v-if="profile.expert.available_per">
                                <i class="mdi mdi-circle online-status-text mr-2" :class="kebabCase(profile.expert.available_per)"></i>{{
                                profile.expert.available_per }}
                            </div>
                            <div class="profile-data f-14" v-if="profile.expert.willingness_to_travel">
                                <i class="mdi mdi-airplane text-primary mr-2"></i>Working area: &nbsp;<strong
                                class="wt-500">{{ profile.expert.willingness_to_travel }}</strong>
                            </div>
                            <div class="profile-data f-14" v-if="profile.expert.target_day_rate">
                                <i class="mdi mdi-briefcase text-primary mr-2"></i>Rate: &nbsp;<strong
                                class="wt-500">{{ profile.expert.target_day_rate }}</strong>
                            </div>
                            <get-expert-introduction-component v-model="changeModal" :slug="profile.expert.slug"
                                                               v-if="!profile.admin"
                                                               :updated-data="alreadyIntroduced"
                                                               :proceed="proceedAccess"
                                                               @credit-finished="showCreditFinished"
                                                               @already-introduced="showAlreadyIntroduced" />
                            <button v-if="profile.admin" @click="deleteExpert" class="btn btn-danger m-t-15" :disabled="isDeletingExpert">
                                <span class="m-x-10">
                                    <span v-show="isDeletingExpert" class="mdi mdi-spin mdi-loading"></span>
                                    <span >Delete</span>
                                </span>
                            </button>
                        </div>
                        <hr class="m-y-25">
                        <div class="m-b-25">
                            <div class="profile-data">
                                <div class="f-14 text-primary"><i class="mdi mdi-earth mr-2"></i>Country</div>
                                <div>{{profile.expert.country_name}}</div>
                            </div>
                        </div>
                        <div class="m-b-25">
                            <div class="profile-data">
                                <div class="f-14 text-primary"><i class="mdi mdi-map-marker mr-2"></i>Location</div>
                                <div>
                                    <template v-if="access">
                                        {{ accessMore.expert.location}}
                                    </template>
                                    <template v-else>
                                        <span class="blur-text"><span
                                            class="hidden-text">{{ replaceText }}</span></span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <hr class="m-y-25">
                        <div class="m-b-25">
                            <div class="profile-data">
                                <div class="f-14 text-primary"><i class="mdi mdi-at mr-2"></i>Email</div>
                                <div class="blur-text" v-if="!profile.admin">
                                    <template v-if="access">
                                        {{ accessMore.expert.email}}
                                    </template>
                                    <template v-else>
                                        <span class="blur-text"><span
                                            class="hidden-text">{{ replaceText }}</span></span>
                                    </template>
                                </div>
                                <div v-if="profile.admin">
                                    <input type="text" class="form-control mb-2 input-inline" :disabled="isSavingEmail" v-model="accessMore.expert.email">
                                    <button class="btn btn-primary" type="button" :disabled="isSavingEmail" @click="expertEmailUpdate">
                                        <span v-show="isSavingEmail" class="mdi mdi-spin mdi-loading"></span>
                                        <span >Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="m-b-25">
                            <div class="profile-data">
                                <div class="f-14 text-primary"><i class="mdi mdi-phone mr-2"></i>Phone</div>
                                <div class="blur-text">
                                    <template v-if="access">
                                        {{ accessMore.expert.phone}}
                                    </template>
                                    <template v-else>
                                        <span class="blur-text"><span
                                            class="hidden-text">{{ replaceText }}</span></span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <hr class="m-y-25">
                        <div class="m-b-25">
                            <div class="profile-data">
                                <div class="f-14 text-primary">Resume</div>
                                <template v-if="access">
                                    <a v-if="accessMore.expert.resume !== null" class="btn btn-light m-t-10 p-x-20"
                                        target="_blank"
                                       :href="accessMore.expert.resume"><i
                                        class="mdi mdi-file-pdf mr-2"></i>Download
                                        resume</a>
                                    
                                    <a v-if="accessMore.expert.resume === null" href="javascript:void(0);" class="btn btn-outline-info inactiveLink m-t-15"><i class="mdi mdi-file-pdf mr-2">
                                        </i>
                                        Resume not available
                                    </a>
                                </template>
                                <template v-else>
                                    <span class="blur-text"><span class="hidden-text"><a
                                        class="btn btn-light m-t-10 p-x-20" href="javascript:void(0)"><i
                                        class="mdi mdi-file-pdf mr-2"></i>Download resume</a></span></span>
                                </template>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-md col-12 col-profile-detail-full">
                <div class="p-xl-45">
                    <div class="card shadow-sm p-md-30 p-x-15 p-y-30 card-expert-detail">
                        <template v-if="profile.admin">
                            <h3 class="text-primary f-20 wt-300"><i class="mdi mdi-link mr-3"></i>Connection</h3>
                            <div class="table-cell">
                                <table class="table-detail f-14">
                                    <tbody>
                                    <tr>
                                        <th>
                                            <div class="table-cell">SOURCE
                                                <div class="f-10">Origin of Expert</div>
                                            </div>
                                        </th>
                                        <td>
                                            <div class="table-cell">{{ profile.expert.source }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="table-cell">MANAGER
                                                <div class="f-10">Main point of contact in Bayes</div>
                                            </div>
                                        </th>
                                        <td>
                                            <div class="table-cell">{{ profile.expert.manager}}</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="m-y-30">
                        </template>
                        <h3 class="text-primary f-20 wt-300"><i class="mdi mdi-domain mr-3"></i>Industry, Sector, Company
                        </h3>
                        <div class="table-overflow">
                            <table class="table-detail f-14">
                                <tbody>
                                <tr>
                                    <th>
                                        <div class="table-cell">INDUSTRY
                                            <div class="f-10">High Level Industry</div>
                                        </div>
                                    </th>
                                    <template v-for="industry in profile.industries">
                                        <td>
                                            <div class="table-cell">{{industry.industry}}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">SECTOR
                                            <div class="f-10">Sector within industry</div>
                                        </div>
                                    </th>
                                    <template v-for="industry in profile.industries">
                                        <td>
                                            <div class="table-cell">{{industry.sector}}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">SUBSECTOR
                                            <div class="f-10">Specific activity within industry</div>
                                        </div>
                                    </th>
                                    <template v-for="industry in profile.industries">
                                        <td>
                                            <div class="table-cell">{{industry.sub_sector}}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">COMPANY
                                            <div class="f-10">Company name</div>
                                        </div>
                                    </th>
                                    <template v-for="industry in profile.industries">
                                        <td>
                                            <div class="table-cell">{{industry.company}}</div>
                                        </td>
                                    </template>
                                    <!-- <td v-for="industry in profile.industries">
                                        <div class="table-cell">
                                            <div class="blur-text" v-if="!access"><span class="hidden-text">{{replaceText}}</span>
                                            </div>
                                            <div v-else v-for="company in accessMore.company">
                                                <template v-if="industry.expert_industry_id in company">
                                                    {{ company[industry.expert_industry_id] }}
                                                </template>
                                            </div>
                                        </div>
                                    </td> -->

                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr class="m-y-30">
                        <h3 class="text-primary f-20 wt-300"><i class="mdi mdi-star mr-3"></i>Department, Function, Topic </h3>
                        <div class="table-overflow">
                            <table class="table-detail f-14">
                                <tbody>
                                <tr>
                                    <th>
                                        <div class="table-cell">DEPARTMENT
                                            <div class="f-10">Main department</div>
                                        </div>
                                    </th>
                                    <template v-for="experience in profile.experience">
                                        <td>
                                            <div class="table-cell">{{ experience.department }}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">FUNCTIONAL AREA
                                            <div class="f-10">Functional area within department</div>
                                        </div>
                                    </th>
                                    <template v-for="experience in profile.experience">
                                        <td>
                                            <div class="table-cell">{{ experience.functional_area }}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">TOPIC
                                            <div class="f-10">Specific name of function</div>
                                        </div>
                                    </th>
                                    <template v-for="experience in profile.experience">
                                        <td>
                                            <div class="table-cell">{{ experience.topic }}</div>
                                        </td>
                                    </template>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr class="m-y-30">
                        <h3 class="text-primary f-20 wt-300"><i class="mdi mdi-translate mr-3"></i>Language</h3>
                        <div class="table-overflow">
                            <table class="table-detail f-14">
                                <tbody>
                                <tr>
                                    <th>
                                        <div class="table-cell">LANGUAGE MT
                                            <div class="f-10">Mother tongue</div>
                                        </div>
                                    </th>
                                    <td>
                                        <div class="table-cell">{{ profile.expert.language_mt }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">LANGUAGE L2
                                            <div class="f-10">Fluent</div>
                                        </div>
                                    </th>
                                    <td>
                                        <div class="table-cell">{{ profile.expert.language_fluent }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">LANGUAGE L3
                                            <div class="f-10">Business level</div>
                                        </div>
                                    </th>
                                    <td>
                                        <div class="table-cell">{{ profile.expert.language_business }}</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr class="m-y-30">
                        <h3 class="text-primary f-20 wt-300"><i class="mdi mdi-school mr-3"></i>Education &amp;
                            Certification
                        </h3>
                        <div class="table-overflow">
                            <table class="table-detail f-14">
                                <tbody>
                                <tr>
                                    <th>
                                        <div class="table-cell">TYPE</div>
                                    </th>
                                    <template v-for="education in profile.education">
                                        <td>
                                            <div class="table-cell">{{ education.type }}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">NAME</div>
                                    </th>
                                    <template v-for="education in profile.education">
                                        <td>
                                            <div class="table-cell">{{ education.name }}</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">YEAR</div>
                                    </th>

                                    <template v-for="education in profile.education">
                                        <td>
                                            <div class="table-cell">{{ education.year }}</div>
                                        </td>
                                    </template>

                                </tr>
                                <tr>
                                    <th>
                                        <div class="table-cell">LICENCE NUMBER</div>
                                    </th>

                                    <template v-for="education in profile.education">
                                        <td>
                                            <div class="table-cell">{{ education.license_number }}</div>
                                        </td>
                                    </template>
                                </tr>
                                </tbody>


                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="intro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="d-flex">
                            <div><i class="mdi mdi-information-outline f-35 f-primary mr-3" style="line-height: 1;"></i>
                            </div>
                            <div>Bayes' Company will introduce you to the expert by e-mail, in which both of you will
                                receive
                                contact details.
                                <p class="m-t-15">
                                    By submitting this form you acknowledge the <q><a target="_blank" :href="rulesOfEngagementLink">Rules of Engagement</a></q>
                                </p>
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light p-x-30" type="button" data-dismiss="modal" @click="cancelled">
                            Cancel
                        </button>
                        <button class="btn btn-secondary p-x-30" type="button" data-dismiss="modal" @click="confirmed">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="message" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="card card-body shadow p-md-45 ">
                        <div class="row">
                            <div class="col-auto"><i class="mdi mdi-information-outline f-35 f-primary mr-3" style="line-height: 1;"></i></div>
                            <div class="col">
                                <h1 class="h4 wt-300">Introduction sent</h1>
                                <div class="f-14">Email has been sent to both successfully </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueLoadImage from '../../base/ImageItem'
    import GetExpertIntroductionComponent from "./GetExpertIntroductionComponent";
    import StringMixin from "../../../mixins/StringMixin.js"

    export default {
        name: "ExpertProfileComponent",
        mixins: [StringMixin],
        components: {GetExpertIntroductionComponent,VueLoadImage},
        props: ['detail','rulesOfEngagementLink'],
        data() {
            return {
                profileDetails: {},
                accessMore: {},
                hasAccess: false,
                replaceText: 'X0X0X0X',
                clickConfirm: false,
                alreadyIntroduced: false,
                changeModal: false,
                isSavingEmail : false,
                isDeletingExpert : false
            }
        },
        methods: {
            deleteExpert(){
                let self = this;
                let expertSlug = self.detail.expert.slug; 
                self.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Delete'
                    }).then((result) => {
                        if(result.value){
                            self.isDeletingExpert = true;
                            axios.delete(`/api/expert/${expertSlug}`)
                            .then(res => {
                                self.$swal({
                                    icon: 'success',
                                    title: res.data.message,
                                    showConfirmButton: true,
                                    timer: 3000
                                }).then((result)=>{
                                    location.href = res.data.redirectLink;
                                });
                            })
                            .catch(error => {
                                let errorResponse = error.response;
                                if (errorResponse.status == 422) {
                                    let errorMessage = errorResponse.data.errors[0]
                                    self.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: errorMessage
                                    });
                                    return;
                                }
                                let errorMessage = errorResponse.data.message
                                self.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: errorMessage
                                });
                                console.error(err); 
                            })
                            .then(function(){
                                self.isDeletingExpert = false;
                            });
                        }
                        self.isDeletingExpert = false;
                    });
            },
            expertEmailUpdate(){
                let self = this;
                let expertSlug = self.detail.expert.slug; 
                self.isSavingEmail = true;
                axios.put(`/api/expert-email-update/${expertSlug}`,{
                    email : self.accessMore.expert.email
                })
                .then(res => {
                    self.$swal({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
                .catch(error => {
                    let errorResponse = error.response;
                    if (errorResponse.status == 422) {
                    let errorMessage = errorResponse.data.errors[0]
                        self.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: errorMessage
                        });
                        return;
                    }
                    let errorMessage = errorResponse.data.message
                    self.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: errorMessage
                    });
                    console.error(err); 
                }).then(function(){
                    self.isSavingEmail = false;
                });
            },
            showCreditFinished(response) {
                this.proceedAccess = false;
                this.$swal({
                    text: response.message
                });
            },
            showAlreadyIntroduced(response) {
                this.$swal({
                    text: response.message,
                    timer: 1500,
                    timerProgressBar: true,
                    onClose: () => {
                    location.reload();
                    }
                });
            },
            confirmed: function () {
                this.clickConfirm = true;
            },
            cancelled: function () {
                this.clickConfirm = false;
            },
            checkIntroduced: function () {
                if ('accessMore' in this.detail) {
                    this.accessMore = this.detail.accessMore;
                }
                let introducedAlready = false;
                if ('introduced' in this.detail) {
                    introducedAlready = this.detail.introduced;
                }
                this.alreadyIntroduced = introducedAlready;
                this.clickConfirm = introducedAlready;
            },
            popUpModal () {
                if (this.changeModal) {
                    setTimeout(()=>{
                        let $ele = document.getElementById("message");
                        if ($ele) {
                            $ele.classList.toggle('show');
                            $ele.setAttribute('style', 'padding-right: 17px; display: block;');
                            $ele.removeAttribute('aria-hidden');
                            $ele.setAttribute('aria-modal', 'true');
                        }
                    },300);
                    setTimeout(()=>{
                        let $ele = document.getElementById("message");
                        if ($ele) {
                            $ele.classList.toggle('show');
                            $ele.setAttribute('style', 'display: none;');
                            $ele.removeAttribute('aria-modal');
                            $ele.setAttribute('aria-hidden', 'true');

                        }
                    },2000)

                }
            }
        },
        computed: {
            profile: function () {
                return this.profileDetails
            },
            access: function () {
                return this.hasAccess;
            },
            proceedAccess: {
                // getter
                get: function() {
                    return this.clickConfirm;
                },
                // setter
                set: function(newValue) {
                    this.clickConfirm = false;
                }
            }
        },
        watch: {
            detail: {
                deep: true,
                handler(n, o) {
                    this.profileDetails = this.detail;
                    this.checkIntroduced();
                }
            },
            accessMore: {
                deep: true,
                immediate: true,
                handler(n, o) {
                    if (n) {
                        if ('expert' in n) this.hasAccess = true;
                    }
                }
            },
            changeModal : {
                deep: true,
                immediate: true,
                handler(n, o) {
                    if (n) {
                        this.popUpModal();
                    }
                }
            }
        },
        created() {
            this.profileDetails = this.detail;
            this.checkIntroduced();
        }
    }
</script>

<style scoped>
    .inactiveLink {
        pointer-events: none;
        cursor: default;
    }
</style>