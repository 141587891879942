const roleFunctions = [
    {
        id: 1,
        name: 'Deal Partner (Investor)',
    },
    {
        id: 2,
        name: 'Deal Team (Investor)',
    },
    {
        id: 3,
        name: 'Operating Partner (Investor)',
    },
    {
        id: 4,
        name: 'Operating Team (Investor)',
    },
    {
        id: 5,
        name: 'Value Creation / Transformation Officer (Investor)',
    },
    {
        id: 6,
        name: 'CEO (Company)',
    },
    {
        id: 7,
        name: 'CFO (Company)',
    },
    {
        id: 8,
        name: 'COO (Company)',
    },
    {
        id: 9,
        name: 'HR (Company)',
    },
    {
        id: 10,
        name: 'Other',
    }
];

const companyTypes = [
    {
        id: 1,
        name : 'Other',
    }
]

let orderedRoleFunctions = roleFunctions.slice(0);
orderedRoleFunctions.sort(function (object_1, object_2) {
    var x = object_1.name.toLowerCase();
    var y = object_2.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
});

let orderedCompanyTypes = companyTypes.slice(0);
orderedCompanyTypes.sort(function (object_1, object_2) {
    var x = object_1.name.toLowerCase();
    var y = object_2.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
});

export default { roleFunctions, orderedRoleFunctions, companyTypes, orderedCompanyTypes}