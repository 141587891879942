<template>
    <ul>
        <li @click.stop="" style="cursor:text" v-for="(element,index) in options" v-show="element.show" v-html="transformResultString(element.name)"></li>
        <li style="list-style: none" v-show="options.length > 1">
            <a href="javascript:void(0)" @click.stop="toggleList()">{{ collabsibleText }} <span class="mdi" :class="visibility ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline'"></span></a
        ></li>
    </ul>
</template>
<script>
export default {
    props: {
        lists : {
            type : Array,
            required : true
        },
        showMore : {
            type : String,
            default : 'See More'
        },
        hide : {
            type : String,
            default : 'Hide'
        },
        searchFilters:{
            type: Array,
            default: () => { return []; }
        }
    },
    data(){
        return {
            options : [],
            visibility : false
        }
    },
    methods:{
        transformResultString(resultString){
            this.searchFilters.forEach(el =>{
                resultString = resultString.replace(el,`<b>${el}</b>`)
            });
            return resultString;
        },
        toggleList(){
            this.visibility = !this.visibility;
            for (let index = 1; index < this.options.length; index++) {
                this.options[index].show = !this.options[index].show ;
            }
        }
    },
    computed : {
        collabsibleText() {
            return this.visibility == false ? this.showMore : this.hide;
        }
    },
    watch : {
        lists: {
            immediate: true,
            handler (newVal, oldVal) {
                this.options = [];
                this.visibility = false;
                let lists = [];
                newVal.forEach((element,index) => {
                    lists.push({
                        name : element,
                        show : index == 0 ? true : false
                    });
                });

                this.options = lists;
            }
        }
    }
}
</script>
