<template> 
  <div class="modal fade" id="error-modal" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="card card-body shadow p-md-45 bg-danger text-white"><a class="card-close text-white" data-dismiss="modal" href="#"><i class="mdi mdi-close"></i></a>
            <div class="row">
              <div class="col-auto"><i class="mdi mdi-alert-decagram h4 text-white o-7"></i></div>
              <div class="col">
                <h1 class="h4 wt-300 text-white">Oops, error</h1>
                <div class="f-14">Sorry, we could not register you at this moment. Please contact us at support@bayes.nl for further info.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    methods:{
        showModal(){
            $('#error-modal').modal('show');
        }
    }
}
</script>