/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
window.Vue = require('vue');
window.moment = require('moment');

import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect';
import VueSweetalert2 from 'vue-sweetalert2';
 
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
const sweetAlertOptions = {
    confirmButtonColor: '#f47a00',
    cancelButtonColor: '#ff7674',
};
 
Vue.use(VueSweetalert2,sweetAlertOptions);
Vue.use(Vuelidate)

require('./bootstrap');

//import 'vue-multiselect/dist/vue-multiselect.min.css'

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.component('multiselect', Multiselect);
Vue.component('expert-search-component', require('./components/frontend/expert/SearchComponent.vue').default);
Vue.component('registration-component', require('./components/frontend/registration/RegistrationComponent.vue').default);
Vue.component('expert-profile-component', require('./components/frontend/expert/ExpertProfileComponent.vue').default);
Vue.component('expert-profile-request-edit-component', require('./components/frontend/expert/edit/Edit.vue').default);
// Vue.component('education-certifications', require('./components/frontend/expert/edit/EducationCertifications.vue').default);



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
