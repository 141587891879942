<template>
    <section class="map border-bottom border-top" >
      <div class="position-relative">
        <GChart  id="chart_div" style="width: 100%; height: auto; overflow:hidden" 
            :settings="{ packages: ['geochart'], mapsApiKey: 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY' }"
            type="GeoChart"
            :data="chartData"
            :options="chartOptions"
            :events="chartEvents"
            ref="gChart"
        />
        <!-- <div class="map-legend position-absolute f-14" style="bottom:40px;left:30px;left:max(30px, 2.6vw);" v-if="expertCountryData.length!=0">Amount of experts</div> -->
      </div>
    </section>
</template>
<script>
import { GChart } from 'vue-google-charts'
export default {
    name: "MapComponent",
    components: {
        GChart
    },
    props:{

        expertCountryData:{
            type: Array,
            default: function () {
                return []
            }
        },
        removeSelection:{
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data () {
        return {
            // Array will be automatically processed with visualization.arrayToDataTable function
            chartData: this.expertCountryData,
            chartOptions: {
                region: '150', // Western Europe
                keepAspectRatio: false,
                colorAxis: {colors: ['#eaeaf6', '#090d5f']},
                dataMode:'markers',
                legend: 'none'
            },
            chartEvents: {
                regionClick: (region) => {  
                    let self = this;
                    if (! self.removeSelection.includes(region.region) && isNaN(region.region)) {
                        axios.get(`/api/country/${region.region}`)
                        .then(res => {
                            let countryData = res.data;
                            self.$emit('selected',countryData);
                        })
                        .catch(err => {
                            console.error(err); 
                        })     
                    }   
                }
            }
        }
    }
}
</script>
